import { useCallback, useEffect, useState, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Pagination } from 'services/search';
import { initialPagination } from 'ui/components/Table/ItemsTable';
import { logErrorCtx } from 'app/logging';
import _ from 'lodash';

import { PaginatedFetchFunction, FetchByIdFunction } from './types';

export const useAsyncAutocomplete = <T>(
  value: T | number | null,
  paginatedFetchFunction: PaginatedFetchFunction<T>,
  fetchByIdFunction: FetchByIdFunction<T> | null,
  dataPagination: Pagination = initialPagination
) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [innerValue, setInnerValue] = useState<T | null>(null);
  const [pagination, setPagination] = useState<Pagination>(dataPagination);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (typeof value === 'number') {
      if (value < 1) {
        setInnerValue(null);
        return;
      }

      if (value && fetchByIdFunction) {
        (async () => {
          setIsLoading(true);
          const resData = await fetchByIdFunction(value);
          if (mounted.current) {
            setInnerValue(resData);
            setIsLoading(false);
          }
        })();
      }
    } else {
      setInnerValue(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getData = useCallback(
    async (newPagination?: Pagination, clearState: boolean = false) => {
      setIsLoading(true);

      try {
        const { data: resData, pagination: resPagination } =
          await paginatedFetchFunction(
            newPagination || pagination,
            searchValue
          );

        setData((old) => {
          if (clearState) {
            return resData;
          }
          return _.uniqBy([...old, ...resData], 'id');
        });

        setPagination(resPagination);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error in Autocomplete', {
          error,
          stackTrace: error.stack,
          title: 'Error while getting next page',
          description: 'paginatedFetchFunction  Not Fetched from Server',
          component: 'AutoComplete(Global)',
        });
      }
      setIsLoading(false);
    },
    [paginatedFetchFunction, pagination, searchValue]
  );

  const getDebouncedCustomers = useDebouncedCallback(async () => {
    getData(dataPagination, true);
  }, 300);

  useEffect(() => {
    // when autocomplete values is changed and it's not null
    // we want clear state and fetch new data
    if (open) {
      getDebouncedCustomers();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onNextPage = useCallback(() => {
    if (data.length >= pagination.totalRows) {
      return;
    }

    const newPagination: Pagination = {
      ...pagination,
      page: pagination.page + 1,
    };
    setPagination(newPagination);
    getData(newPagination);
  }, [pagination, data.length, getData]);

  const onCloseAutocomplete = useCallback(() => {
    setOpen(false);
    setData([]);
    setPagination(dataPagination);
  }, [dataPagination]);

  const onOpenAutocomplete = useCallback(() => {
    setOpen(true);
    setData([]);
    getData();
  }, [getData]);

  return {
    onOpenAutocomplete,
    onCloseAutocomplete,
    onNextPage,
    data,
    open,
    isLoading,
    setSearchValue,
    innerValue,
    setInnerValue,
    setData,
    pagination,
  };
};

export const useMultiAsyncAutocomplete = <T>(
  value: T[] | number[] | null,
  paginatedFetchFunction: (
    pagination: Pagination,
    searchValue: string | null
  ) => Promise<{ data: T[]; pagination: Pagination }>,
  fetchByIdsFunction: (
    pagination: Pagination,
    searchValue: string | null,
    ids?: number[]
  ) => Promise<{ data: T[]; pagination: Pagination }>,
  dataPagination: Pagination = initialPagination,
  ids?: number[]
) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [innerValue, setInnerValue] = useState<T[] | null>();
  const [pagination, setPagination] = useState<Pagination>(dataPagination);

  useEffect(() => {
    // if value is an array of numbers (ids) and there is no innerValue fetch data by ids
    if (
      (Array.isArray(value) && value.length && typeof value[0] === 'number') ||
      ids
    ) {
      if (!innerValue || !innerValue.length) {
        (async () => {
          const resData = await fetchByIdsFunction(
            pagination,
            searchValue,
            (value as number[]) || ids
          );
          setInnerValue(resData.data);
        })();
      }
      // otherwise set innerValue to value
    } else {
      setInnerValue(value as T[] | null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, ids]);

  const getData = useCallback(
    async (newPagination?: Pagination, clearState: boolean = false) => {
      setIsLoading(true);

      try {
        const { data: resData, pagination: resPagination } =
          await paginatedFetchFunction(
            newPagination || pagination,
            searchValue
          );

        setData((old) => (clearState ? resData : [...old, ...resData]));
        setPagination(resPagination);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error in Autocomplete', {
          error,
          stackTrace: error.stack,
          title: 'Error while getting next page',
          description: 'paginatedFetchFunction  Not Fetched from Server',
          component: 'AutoComplete(Global)',
        });
      }

      setIsLoading(false);
    },
    [paginatedFetchFunction, pagination, searchValue]
  );

  const getDebouncedCustomers = useDebouncedCallback(async () => {
    getData(dataPagination, true);
  }, 300);

  useEffect(() => {
    // when autocomplete values is changed and it's not null
    // we want clear state and fetch new data
    if (open) {
      getDebouncedCustomers();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onNextPage = useCallback(() => {
    if (data.length >= pagination.totalRows) {
      return;
    }

    const newPagination: Pagination = {
      ...pagination,
      page: pagination.page + 1,
    };
    setPagination(newPagination);
    getData(newPagination);
  }, [pagination, data.length, getData]);

  const onCloseAutocomplete = useCallback(() => {
    setOpen(false);
    setData([]);
    setPagination(dataPagination);
  }, [dataPagination]);

  const onOpenAutocomplete = useCallback(() => {
    setOpen(true);
    getData();
  }, [getData]);

  return {
    onOpenAutocomplete,
    onCloseAutocomplete,
    onNextPage,
    data,
    open,
    isLoading,
    setSearchValue,
    innerValue,
    setInnerValue,
    setData,
    pagination,
  };
};

export const useScrollPosition = (data: any[]) => {
  const listRef = useRef<HTMLDivElement>(null);
  const scrollPosition = useRef(0);

  const captureScrollPosition = () => {
    if (listRef.current) {
      scrollPosition.current = listRef.current.scrollTop;
    }
  };

  const restoreScrollPosition = () => {
    if (listRef.current) {
      listRef.current.scrollTop = scrollPosition.current;
    }
  };

  useEffect(() => {
    restoreScrollPosition();
  }, [data]);

  return { listRef, captureScrollPosition };
};
