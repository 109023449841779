import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, TextField } from '@mui/material';
import AutoComplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

import { activeUserHasPermission } from 'services/user/redux';
import { Pagination } from 'services/search';
import { PaginationDirection } from 'services/url';
import { defaultPagination, replaceValueInCollection } from 'helpers';
import { postTag, Tag } from 'services/tags';
import { initialTag } from 'services/tags/consts';

import {
  resolvedFetchTagsFunction,
  tagsAsyncAutocompleteFilterOptions,
  tagsAsyncAutocompleteOptionLabel,
} from './helpers';
import { TagsAsyncAutocompleteProps } from './types';
import { useTagsAutocompleteStyles } from './styled';
import TagsModal from './TagsModal';
import {
  useMultiAsyncAutocomplete,
  useScrollPosition,
} from '../Autocomplete/hooks';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const initialTagPagination: Pagination = {
  ...defaultPagination,
  pageSize: 50,
  sort: { sortBy: 'name', direction: PaginationDirection.Ascending },
};

const TagsAsyncAutocomplete: React.FC<TagsAsyncAutocompleteProps> = (props) => {
  const {
    onChange,
    dataQa,
    permissions = [],
    disabled,
    label,
    value,
    placeholder = 'Select tags',
    error,
    required,
    additionalInputProps,
    helperText,
    expands,
    disableTagsModal = false,
    onTagNameChange,
    onTagDelete,
    ids,
  } = props;

  const {
    data: tags,
    isLoading,
    innerValue,
    open,
    onCloseAutocomplete,
    onNextPage,
    onOpenAutocomplete,
    setData: setTags,
    setSearchValue,
    setInnerValue,
  } = useMultiAsyncAutocomplete(
    value,
    resolvedFetchTagsFunction(expands),
    resolvedFetchTagsFunction(expands, ids),
    initialTagPagination,
    ids
  );

  const classes = useTagsAutocompleteStyles();
  const canEdit = useSelector(activeUserHasPermission(permissions));
  const { listRef, captureScrollPosition } = useScrollPosition(tags);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newActiveTag, setNewActiveTag] = useState<Tag>(initialTag);

  const isDisabled = disabled || !canEdit;
  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = useCallback(() => setIsModalOpen(false), []);

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      setSearchValue(val || null);
      setNewActiveTag({
        ...initialTag,
        name: val,
      });
      return;
    }

    setSearchValue(null);
  };

  const handleChange = async (e: any, tags: Tag[]) => {
    let newTags = tags;

    const newTagIndex = tags.findIndex((tag) => tag.id === null || tag.id < 0);

    if (newTagIndex > -1) {
      try {
        const newTag = await postTag(newActiveTag);
        setTags((old) => [...old, newTag]);
        newTags = replaceValueInCollection(tags, newTag, newTagIndex)!;
      } catch {
        return;
      }

      setNewActiveTag(initialTag);
    }

    setInnerValue(newTags);
    onChange(newTags);
  };

  const handleTagDelete = useCallback(
    (id: number) => {
      const index = value.findIndex((i) => i.id === id);
      if (index > -1) {
        onChange([...value.slice(0, index), ...value.slice(index + 1)]);
      }

      if (onTagDelete) {
        onTagDelete(id);
      }
      setTags((old) => old.filter((i) => !i.id));
    },
    [value, onChange, onTagDelete, setTags]
  );

  // we want to change name on updated tag if that tag is selected
  const handleTagChange = useCallback(
    (tag: Tag) => {
      const index = value.findIndex((i) => i.id === tag.id);
      if (index > -1) {
        onChange(replaceValueInCollection(value, tag, index)!);
      }

      if (onTagNameChange) {
        onTagNameChange(tag);
      }
    },
    [value, onChange, onTagNameChange]
  );

  const renderInput = useCallback(
    (params) => {
      // params.InputProps.endAdornment is component with 2 children
      // first child is clear button, so we want to set it to null
      const endAdornmentWithoutClear = {
        ...params.InputProps.endAdornment,
        props: {
          ...params.InputProps.endAdornment.props,
          children: [null, params.InputProps.endAdornment.props.children[1]],
        },
      };

      return (
        <TextField
          className={'redesign'}
          // we are omitting props that would overwrite our styling in TextField
          {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
          {...params}
          label={label}
          fullWidth
          InputLabelProps={{ shrink: true, margin: 'dense' }}
          required={required}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          variant="outlined"
          size="small"
          disabled={disabled}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'nope',
            'data-qa': dataQa,
            'data-lpignore': 'true',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {endAdornmentWithoutClear}
              </React.Fragment>
            ),
            classes: {
              root: classes.input,
            },
            ...additionalInputProps,
          }}
        />
      );
    },
    [
      error,
      helperText,
      disabled,
      additionalInputProps,
      dataQa,
      isLoading,
      label,
      placeholder,
      classes.input,
      required,
    ]
  );

  return (
    <>
      <Box display="flex" alignItems="center">
        <AutoComplete
          // TODO : RESTYLING : minor style adjustment needed for now until we use the FBOAutoSelect component
          style={{ marginTop: '0px', marginBottom: '0px' }}
          className={'redesign multi-line'}
          options={tags}
          open={open}
          disabled={isDisabled}
          value={innerValue || []}
          disableClearable={required}
          fullWidth
          onOpen={onOpenAutocomplete}
          onClose={onCloseAutocomplete}
          onChange={handleChange}
          isOptionEqualToValue={(option, val) => option.id === val.id}
          getOptionLabel={tagsAsyncAutocompleteOptionLabel}
          filterOptions={tagsAsyncAutocompleteFilterOptions}
          onInputChange={handleAutocompleteInputChange}
          multiple
          noOptionsText={isLoading ? 'Loading...' : 'No options'}
          ListboxProps={{
            onScroll: (event: React.SyntheticEvent) => {
              const node = event.currentTarget;
              if (
                Math.ceil(node.scrollTop + node.clientHeight) ===
                node.scrollHeight
              ) {
                onNextPage();
              }
            },
            // @ts-ignore
            ref: listRef,
            onScrollCapture: captureScrollPosition,
          }}
          renderInput={renderInput}
          clearIcon={
            dataQa && (
              <CloseIcon
                fontSize="small"
                data-qa={`${dataQa}-autocomplete-clear-icon`}
              />
            )
          }
          popupIcon={
            dataQa && (
              <ArrowDropDownIcon
                fontSize="small"
                data-qa={`${dataQa}-autocomplete-dropdown-icon`}
              />
            )
          }
        />
        {!disableTagsModal && (
          <Box ml={1}>
            <FBOButton
              variant="secondary"
              color="neutral"
              size="medium"
              onClick={handleOpenModal}
              disabled={isDisabled}
              data-qa="manage-tags-button"
            >
              Manage
            </FBOButton>
          </Box>
        )}
      </Box>
      {!disableTagsModal && (
        <TagsModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onTagDelete={handleTagDelete}
          onTagNameChange={handleTagChange}
        />
      )}
    </>
  );
};

export default memo(TagsAsyncAutocomplete);
