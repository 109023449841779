import React, { memo, useCallback, useRef, useState } from 'react';
import { Modal } from 'ui/components/Modal/Modal';

import { customDateRange } from '../consts';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import LocationsAsyncAutocomplete from 'ui/components/Autocomplete/LocationsAsyncAutocomplete/LocationsAsyncAutocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { useRenderTabRowStyle } from '../styled';
import { Location } from 'services/locations';
import { getConsumptionRate, ItemReorderPoint } from 'services/items';
import _ from 'lodash';
import { DateRange } from 'materialui-daterange-picker';
import { ReorderPointAddProps } from './types';
import { logErrorCtx } from 'app/logging';
import { useHandleTextFieldChange } from 'services/forms/hooks';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const ReorderTabModal: React.FC<ReorderPointAddProps> = (props) => {
  const {
    show,
    itemId,
    reorderPoint,
    setReorderPoint,
    onCancelClicked,
    onApplyClicked,
    editPermissions,
    isNewItem,
    onAlertClick,
    columnRender,
    validationErrors,
  } = props;

  const rowClasses = useRenderTabRowStyle();

  const [autocompleteActiveLocation, setAutocompleteActiveLocation] =
    useState<Location | null>(null);

  const dateInput = useRef<HTMLInputElement>(null);

  const handleNumberFieldChange = useHandleTextFieldChange(
    setReorderPoint,
    reorderPoint,
    true
  );

  const handleDateChange = useCallback(
    async (value: DateRange) => {
      const locationId = _.get(
        autocompleteActiveLocation,
        'id',
        reorderPoint.locationId
      );

      try {
        const dailyConsumptionRate = await getConsumptionRate(
          itemId,
          locationId,
          value
        );

        setReorderPoint((oldItemReorderPoint: ItemReorderPoint) => ({
          ...oldItemReorderPoint,
          dailyConsumptionRate,
        }));
      } catch (e) {
        logErrorCtx('Error in getConsumptionRate', {
          error: e as Error,
          stackTrace: (e as Error).stack,
          component: 'ReorderTabModal',
          title: 'Error in getConsumptionRate',
          description: 'Error in getConsumptionRate',
        });
      }
    },
    // eslint-disable-next-line
    [setReorderPoint, reorderPoint]
  );

  const dateClick = useCallback(() => {
    if (dateInput.current) {
      dateInput.current.click();
    }
  }, [dateInput]);

  const handleAutocompleteChange = useCallback(
    (currentLocation: Location | null) => {
      setAutocompleteActiveLocation(currentLocation);

      const newReorderStuff = {
        ...reorderPoint,
        itemId: itemId,
        location: currentLocation ? currentLocation : null,
        locationId: currentLocation ? currentLocation.id : null,
      };

      setReorderPoint(newReorderStuff);
    },
    [setReorderPoint, reorderPoint, itemId]
  );

  return (
    <Modal
      open={show}
      withoutDefaultPadding
      title={`${isNewItem ? 'Add' : 'Update '} reorder point`}
      applyLabel={isNewItem ? 'Add' : 'Update'}
      cancelLabel="Cancel"
      isLoading={false}
      onApplyClicked={onApplyClicked}
      onCancelClicked={onCancelClicked}
      dataQa="item-reorders"
      maxWidth="lg"
      disableEnter
    >
      <Table>
        <TableHead className={rowClasses.headerStyle}>
          <TableRow>
            {columnRender.map((property, index) => (
              <TableCell
                key={`${property.title}ColumnTitle${index}`}
                className={rowClasses.headerText}
                align={property.align}
                style={{ width: property.width }}
                title={property.title}
              >
                {property.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ height: '220px' }}>
            <TableCell>
              <LocationsAsyncAutocomplete
                value={reorderPoint.location}
                onChange={handleAutocompleteChange}
                parentId={null}
                disabled={!isNewItem}
                permissions={editPermissions}
                dataQa="reorder-point-location-dropdown"
                getLocationLabel={(loc) => loc.path || ''}
                companyWide={false}
                error={!!validationErrors?.location}
              />
            </TableCell>
            <TableCell>
              <Box className={rowClasses.flexBox}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="number"
                  name="dailyConsumptionRate"
                  value={reorderPoint.dailyConsumptionRate}
                  onChange={handleNumberFieldChange}
                  permissions={editPermissions}
                  dataQa="reorder-point-daily-consumption-rate"
                  disableDebounce={true}
                />
                <FBOButton
                  variant="tertiary"
                  color="neutral"
                  size="large"
                  icon="Calendar"
                  onClick={dateClick}
                  data-qa="reorder-points-add-new-button"
                />

                <DateRangePicker
                  additionalInputProps={{ inputRef: dateInput }}
                  value={null}
                  onChange={handleDateChange}
                  className={rowClasses.hiddenInput}
                  applyLabel="Calculate"
                  definedRanges={customDateRange}
                />
              </Box>
            </TableCell>
            <TableCell>
              <TextField
                className="redesign"
                variant="standard"
                type="number"
                name="min"
                value={reorderPoint.min}
                onChange={handleNumberFieldChange}
                permissions={editPermissions}
                dataQa="reorder-point-min"
                disableDebounce={true}
                error={!!validationErrors?.min}
                helperText={validationErrors?.min || ''}
              />
            </TableCell>
            <TableCell>
              <TextField
                className="redesign"
                variant="standard"
                type="number"
                name="max"
                value={reorderPoint.max}
                onChange={handleNumberFieldChange}
                permissions={editPermissions}
                dataQa="reorder-point-max"
                disableDebounce={true}
                error={!!validationErrors?.max}
                helperText={validationErrors?.max || ''}
              />
            </TableCell>
            <TableCell>
              <TextField
                className="redesign"
                variant="standard"
                type="number"
                name="alert"
                value={reorderPoint.alert}
                onChange={handleNumberFieldChange}
                permissions={editPermissions}
                dataQa="reorder-point-alert"
                disableDebounce={true}
              />
            </TableCell>

            <TableCell>
              <Box display="flex" alignItems="center" justifyContent="center">
                <FBOButton
                  variant="tertiary"
                  color="neutral"
                  size="large"
                  icon="Bell"
                  onClick={() => onAlertClick(_.get(reorderPoint, 'id'))}
                  disabled={!editPermissions}
                  data-qa="reorderPoint-alert-button"
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Modal>
  );
};

export default memo(ReorderTabModal);
