import React, { memo, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location } from 'services/locations';
import { Item, ItemType } from 'services/items';
import { useHandleCheckboxChange } from 'services/forms/hooks';

import { ReportFiltersProps } from '../../types';

const CycleCountFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationId: location ? location.id : null,
      }));
    },
    [setParameters]
  );

  const handleItemChange = useCallback(
    (selectedItem: Item | null) => {
      setParameters((old) => ({
        ...old,
        itemId: selectedItem ? selectedItem.id : null,
      }));
    },
    [setParameters]
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        showZeroQuantityItems: checked,
        showQuantity: checked,
        showSerialNumbers: checked,
        selectAllOptional: checked,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%" pt={1}>
            <Box>
              <LocationsAsyncAutocomplete
                label="Location"
                value={parameters.locationId}
                placeholder="Select location"
                companyWide={false}
                onChange={handleLocationChange}
                customQuickSearchColumns={['name', 'description']}
              />
            </Box>
            {parameters.locationId && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(parameters.includeChildrenLocations)}
                    onChange={handleCheckboxChange}
                    name="includeChildrenLocations"
                    color="primary"
                  />
                }
                label="Include Child Locations"
              />
            )}
            <Box>
              <ItemsAutocomplete
                label="Item"
                placeholder="Select item"
                onChange={handleItemChange}
                value={parameters.itemId}
                itemTypes={[ItemType.Inventory]}
              />
            </Box>
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showZeroQuantityItems)}
                  onChange={handleCheckboxChange}
                  name="showZeroQuantityItems"
                  color="primary"
                />
              }
              label="Show Zero Quantity Items"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showQuantity)}
                  onChange={handleCheckboxChange}
                  name="showQuantity"
                  color="primary"
                />
              }
              label="Show Quantity"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showSerialNumbers)}
                  onChange={handleCheckboxChange}
                  name="showSerialNumbers"
                  color="primary"
                />
              }
              label="Show Serial Numbers"
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(CycleCountFilters);
