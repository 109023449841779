export enum Routes {
  LoginPage = '/login',
  LogoutPage = '/logout',
  ForgotPasswordPage = '/forgot-password',
  CreateAccountPage = '/create-account',
  AccountStatusPage = '/account-status',
  ChangePasswordPage = '/change-password',
  TermsOfService = '/terms-of-service',
  QboDisconnect = '/disconnect-qbo',
}
