import { Environment } from './types';

export const isTrunk = () =>
  process.env.REACT_APP_ENVIRONMENT === Environment.TRUNK;

export const isQa = () => process.env.REACT_APP_ENVIRONMENT === Environment.QA;

export const isUpgrade = () =>
  process.env.REACT_APP_ENVIRONMENT === Environment.QA_UPGRADE;

export const isStage = () =>
  process.env.REACT_APP_ENVIRONMENT === Environment.STAGE;

export const isProd = () =>
  process.env.REACT_APP_ENVIRONMENT === Environment.PROD;

export const featureFlagsEnv = () => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  if (env === 'TRUNK') return 'Trunk';
  if (env === 'QA') return 'QA';
  if (env === 'QA-UPGRADE') return 'QA';
  if (env === 'STAGE') return 'Staging';
  if (env === 'PROD') return 'Production';
};
