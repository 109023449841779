import React, { useMemo, memo, useCallback, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

import { MenuItem } from 'ui/components/Menu';
import { IconNames, SupportColors } from 'ui/theme';
import { TextFieldWithActions } from 'ui/components/TextField/TextFieldWithActions';
import {
  Address,
  transformAddressToBillToShipToAddressBeta,
  BillToShipToAddressBeta, // @beta: additionally added for testing
  initialBillToShipToAddressBeta, // @beta: additionally added for testing
} from 'services/addresses';

import TextFieldAddressModal from './TextFieldAddressModal';
import { TextFieldAddressProps } from './types';

const CustomClearIcon = () => (
  <ClearIcon style={{ color: SupportColors.iconOnLight }} />
);

const TextFieldAddressBeta = (props: TextFieldAddressProps) => {
  const {
    value,
    addressChoices,
    placeholder,
    label,
    additionalActionItems = [],
    onChange,
    disabled = false,
    permissions = [],
    customerId = null,
    vendorId = null,
    salesOrder,
    setSalesOrder,
  } = props;

  const [modalVisible, setModalVisible] = useState(false);

  const fullAddressExists: boolean = useMemo(
    () =>
      !!(
        value.name &&
        value.street &&
        value.city &&
        value.state &&
        value.country
      ),
    [value]
  );

  const generateAddressLabel = useCallback(
    // @beta: additionally added for testing
    (adr: Address | BillToShipToAddressBeta) => {
      const fullAddressFields = [
        adr.name,
        adr.companyName,
        adr.attention,
        adr.street,
        adr.street2,
        adr.city,
        adr.postalCode,
        adr.state,
        adr.country,
        adr.phone, // @beta: additionally added for testing
        adr.email, // @beta: additionally added for testing
      ];

      // show only entered fields
      return fullAddressFields.filter((f) => !!f).join(', ');
    },
    []
  );

  const resetSelectedAddress = useCallback(() => {
    // @beta: additionally added for testing
    onChange(initialBillToShipToAddressBeta);
  }, [onChange]);

  const changeToSelectedAddress = useCallback(
    (address: Address) => () => {
      // @beta: additionally added for testing
      onChange(
        transformAddressToBillToShipToAddressBeta(address, salesOrder.customer)
      );
    },
    [onChange]
  );

  const handleModalCancelClicked = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleSaveModalClicked = useCallback(
    // @beta: additionally added for testing
    (newAddress: BillToShipToAddressBeta) => {
      onChange(newAddress);
      setModalVisible(false);
    },
    [onChange]
  );

  const menuActionOptions: MenuItem[] = useMemo(
    () => [
      ...additionalActionItems,
      {
        label: fullAddressExists ? 'Edit' : 'Custom',
        icon: IconNames.Mode,
        separateAfter: !!addressChoices.length,
        onOptionClick: () => setModalVisible(true),
        permissions: permissions,
        disabled: disabled,
      },
      ...addressChoices.map((choice) => ({
        label: generateAddressLabel(choice),
        icon: IconNames.Place,
        onOptionClick: changeToSelectedAddress(choice),
        permissions: permissions,
        disabled: disabled,
      })),
      {
        label: 'Clear',
        icon: <CustomClearIcon />,
        onOptionClick: resetSelectedAddress,
        permissions: permissions,
        disabled: disabled,
      },
    ],
    [
      addressChoices,
      changeToSelectedAddress,
      fullAddressExists,
      generateAddressLabel,
      resetSelectedAddress,
      additionalActionItems,
      permissions,
      disabled,
    ]
  );

  return (
    <>
      <TextFieldWithActions
        type="text"
        label={label}
        placeholder={placeholder || 'Select address'}
        value={generateAddressLabel(value)}
        fullWidth
        actionItems={menuActionOptions}
        disabled={disabled}
      />

      <TextFieldAddressModal
        address={value}
        editMode={fullAddressExists}
        modalVisible={modalVisible}
        onCancel={handleModalCancelClicked}
        onSave={handleSaveModalClicked}
        customerId={customerId}
        vendorId={vendorId}
        salesOrder={salesOrder}
        setSalesOrder={setSalesOrder}
      />
    </>
  );
};

export default memo(TextFieldAddressBeta);
