import moment from 'moment';
import * as yup from 'yup';

import { PermissionType } from 'services/permissions';

import {
  Report,
  ReportFontAndBackgroundColor,
  ReportFontSize,
  ReportId,
  ReportLayout,
  ReportPreset,
  ReportTheme,
  ReportThemeColor,
} from './types';
import { ItemTypeOptions } from 'ui/components/ReportsViewer/components/ReportsViewerSidebar/components/ShippingInvoiceFilters/consts';

export const currentMonth = {
  startDate: moment().startOf('month').toDate(),
  endDate: moment().endOf('month').toDate(),
};

export const reportList: Report[] = [
  {
    id: 1,
    title: 'Inventory On Hand',
    reportId: ReportId.Items,
    description: 'Inventory on Hand Report',
    parameters: {
      locationId: null,
      itemId: null,
      tracking: null,
      showTracking: false,
      selectAllOptional: false,
      showSku: false,
      tagIds: [],
    },
    permission: PermissionType.ReportInventoryOnHand,
  },
  {
    id: 2,
    title: 'Sales Order',
    reportId: ReportId.SalesOrder,
    description: 'Sales Order Report',
    parameters: {
      salesOrderId: null,
      reportTitle: 'Sales Order',
      dateOption: null,
      isInvoice: false,
      showBarcode: false,
      showCompanyAddress: true,
      showCompanyName: true,
      showContact: false,
      showOrderNotes: false,
      showPayments: true,
      showTax: true,
      showHomeCurrency: false,
      columnOrderDateScheduled: true,
      columnItemTotalPrice: true,
      columnItemDiscount: true,
      columnItemType: false,
      columnItemUnitPrice: true,
      columnItemQuantity: true,
      columnCarrier: true,
      columnFobPoint: false,
      columnPaymentTerm: false,
      columnSeller: false,
      columnShipService: true,
      columnShippingTerms: false,
      columnPoNumber: false,
      selectAllOptional: false,
      selectAllColumns: false,
      columnTags: false,
      columnRowNumber: false,
      customFields: [],
      showLineItemNotes: false,
      email: {},
    },
    validation: yup.object().shape({
      salesOrderId: yup.number().typeError('Sales order is required'),
    }),
    permission: PermissionType.ReportSalesOrder,
  },
  {
    id: 3,
    title: 'Cycle Count',
    reportId: ReportId.CycleCount,
    description: 'Cycle Count Report',
    parameters: {
      locationId: null,
      includeChildrenLocations: false,
      itemId: null,
      showZeroQuantityItems: false,
      showQuantity: false,
      showSerialNumbers: false,
      selectAllOptional: false,
    },
    permission: PermissionType.ReportCycleCount,
  },
  {
    id: 4,
    title: 'Customer History',
    reportId: ReportId.CustomerHistory,
    description: 'Customer History Report',
    parameters: {
      groupBy: null,
      representativeId: null,
      locationId: null,
      reportTitle: null,
      saleItemId: null,
      salesOrderId: null,
      dateRange: currentMonth,
      includeCreditReturnItems: false,
      includeMiscSaleItems: false,
      includeMiscCreditItems: false,
      selectAllOptional: false,
      customerId: null,
    },
    permission: PermissionType.ReportCustomerHistory,
  },
  {
    id: 5,
    title: 'Inventory Valuation Summary',
    reportId: ReportId.InventoryValuation,
    description: 'Inventory Valuation Summary Report',
    parameters: {
      groupBy: null,
      itemId: null,
      locationId: null,
      showLocationDetails: false,
      selectAllOptional: false,
      dateTo: null,
      costingMethod: null,
    },

    validation: yup.object().shape({
      dateTo: yup.date().nullable(true),
    }),
    permission: PermissionType.ReportInventoryValuationSummary,
  },
  {
    id: 6,
    title: 'Item Price List',
    reportId: ReportId.ItemPriceList,
    description: 'Item Price List Report',
    parameters: {
      groupBy: false,
      tagId: null,
      saleItemId: null,
      showSku: false,
      showUpc: false,
      selectAllOptional: false,
    },
    permission: PermissionType.ReportItemsPriceList,
  },
  {
    id: 7,
    title: 'Pick',
    reportId: ReportId.Pick,
    description: 'Pick Report',
    parameters: {
      pickId: null,
      showSo: false,
      showSku: false,
      showDescription: false,
      showCustomer: false,
      showItem: true,
      showAddress: false,
      showBarcode: false,
      showCountry: false,
      selectAllOptional: false,
      showItemsInStock: false,
      showItemStatus: false,
      showInventoryStatus: false,
      showTracking: false,
      showItemsInSalesOrderUOM: false,
    },
    validation: yup.object().shape({
      pickId: yup.number().typeError('Pick is required'),
    }),
    permission: PermissionType.ReportPick,
  },
  {
    id: 8,
    title: 'Purchase Order',
    reportId: ReportId.PurchaseOrder,
    description: 'Purchase Order Report',
    parameters: {
      reportTitle: 'Purchase Order',
      purchaseOrderId: null,
      showBarcode: false,
      showCompanyAddress: false,
      showCompanyName: false,
      showLineItem: false,
      showNotes: false,
      showItemName: false,
      showStatus: false,
      showTax: false,
      showHomeCurrency: true,
      columnRowNumber: true,
      columnItemSku: true,
      columnItemDescription: true,
      columnItemId: true,
      columnItemQuantityOrdered: true,
      columnItemType: true,
      columnOrderBuyer: true,
      columnOrderDateScheduled: true,
      columnOrderFobPoint: true,
      columnOrderPaymentTerms: true,
      columnOrderCarrier: true,
      columnShipService: true,
      columnShippingTerms: true,
      selectAllOptional: false,
      selectAllColumns: true,
      email: {},
    },
    validation: yup.object().shape({
      purchaseOrderId: yup.number().typeError('Purchase order is required'),
    }),
    permission: PermissionType.ReportPurchaseOrder,
  },
  {
    id: 9,
    title: 'Packing List',
    reportId: ReportId.PackingList,
    description: 'Packing List Report',
    parameters: {
      shipId: null,
      contactToShow: null,
      orderNumber: null,
      title: null,
      showBackOrderedItems: false,
      showShippingService: false,
      showBarcode: false,
      showCartonInfo: false,
      showCompanyName: false,
      showOrderNotes: false,
      showTracking: false,
      columnDescription: false,
      columnQtyOrdered: false,
      columnQtyShipped: false,
      columnLineNumber: false,
      columnItemNumber: false,
      columnQtyRemaining: false,
      selectAllOptional: false,
      selectAllColumns: false,
      showFobPoint: false,
      showDateScheduled: false,
      showPackageCount: false,
    },
    validation: yup.object().shape({
      shipId: yup.number().typeError('Shipping is required'),
    }),
    permission: PermissionType.ReportPackingList,
  },
  {
    id: 10,
    title: 'Shipping Invoice',
    reportId: ReportId.ShippingInvoice,
    description: 'Shipping Invoice Report',
    parameters: {
      shipId: null,
      showBarcode: false,
      showShippingService: false,
      showCartonInfo: false,
      showCompanyName: false,
      showTotals: false,
      selectAllOptional: false,
      showNotes: false,
      showTracking: false,
      ColumnSeller: true,
      ColumnPONumber: true,
      ColumnPaymentTerm: true,
      ColumnFOBPoint: true,
      ColumnShippingTerms: true,
      ShowPayments: false,
      ColumnRowNumber: true,
      ColumnItemType: true,
      ColumnItemDiscount: true,
      ShowTax: true,
      ShowLineItemNotes: false,
      ShowItemTracking: false,
      ShowMiscSaleItems: false,
      ShowHomeCurrency: false,
      ReportTitle: null,
      DateOption: null,
      ItemTypes: ItemTypeOptions,
    },
    validation: yup.object().shape({
      shipId: yup.number().typeError('Shipping is required'),
    }),
    permission: PermissionType.ReportShippingInvoice,
  },
  {
    id: 11,
    title: 'Receiving List',
    reportId: ReportId.ReceivingList,
    description: 'Receiving List Report',
    parameters: {
      receiptId: null,
      orderBy: null,
      includeItemBarcode: false,
      includeReceivedItems: false,
      showBarcode: false,
      showVendorPartName: false,
      selectAllOptional: false,
    },
    validation: yup.object().shape({
      receiptId: yup.number().typeError('Receipt is required'),
    }),
    permission: PermissionType.ReportReceivingList,
  },
  {
    id: 12,
    title: 'Total Item Sales',
    reportId: ReportId.TotalItemSales,
    description: 'Total Item Sales Report',
    parameters: {
      dateRange: null,
      includeCreditReturnItems: false,
      showUom: false,
      showQuantity: false,
      selectAllOptional: false,
    },
    permission: PermissionType.ReportTotalItemSales,
  },
  {
    id: 13,
    title: 'Reorder',
    reportId: ReportId.Reorder,
    description: 'Reorder Report',
    parameters: {
      locationId: null,
      itemId: null,
      showDefaultVendor: false,
      showAllItemsWithReorderPoints: false,
      showDescription: true,
      selectAllOptional: false,
      showAllItemsWithNoReorderPoints: false,
    },
    permission: PermissionType.ReportReorder,
  },
  {
    id: 15,
    title: 'Purchase Order Summary',
    reportId: ReportId.PurchaseOrderSummary,
    description: 'Purchase Order Summary Report',
    parameters: {
      dateRangeOption: null,
      groupBy: null,
      showQtyType: null,
      locationId: null,
      vendorId: null,
      itemId: null,
      reportTitle: null,
      dateRange: currentMonth,
      showBidRequest: false,
      showFulfilled: false,
      showIssued: false,
      showPartial: false,
      showVoided: false,
      showCost: false,
      selectAllOptional: false,
    },
    permission: PermissionType.ReportPurchaseOrderSummary,
  },
  {
    id: 16,
    title: 'Sales Order Summary',
    reportId: ReportId.SalesOrderSummary,
    description: 'Sales Order Summary Report',
    parameters: {
      dateRangeOption: null,
      groupBy: null,
      shipToStateProvince: null,
      representativeId: null,
      locationId: null,
      saleItemId: null,
      dateRange: currentMonth,
      showEstimate: false,
      showFulfilled: false,
      showIssued: false,
      showInProgress: false,
      showVoided: false,
      columnDateFulfilled: false,
      columnDateScheduled: false,
      columnShipToStateProvince: false,
      columnCustomerPo: false,
      selectAllOptional: false,
      selectAllColumns: false,
      customerId: null,
      class: null,
      tagIds: null,
      columnSalesRep: false,
      showPaymentInfo: false,
      showPaymentType: false,
      showTotalOnly: false,
      showShippedInfo: false,
      columnPaymentTerm: false,
      columnMargin: false,
    },
    permission: PermissionType.ReportSalesOrderSummary,
  },
  {
    id: 17,
    title: 'Barcode',
    reportId: ReportId.Barcode,
    description: 'Barcode Report',
    parameters: {
      itemId: null,
      count: null,
      skip: null,
      showDescription: false,
      showPrice: false,
      showUpc: false,
      selectedAllOptional: false,
      producedFromType: null,
    },
    permission: PermissionType.ReportBarcode,
  },
  {
    id: 18,
    title: 'On Hand By Expiration',
    reportId: ReportId.OnHandByExpiration,
    description: 'On Hand By Expiration Report',
    parameters: {
      orderBy: 'location',
      status: 'All',
      locationId: null,
      itemId: null,
      dateRange: {},
    },
    permission: PermissionType.ReportOnHandByExpiration,
  },
  {
    id: 19,
    title: 'Location Barcode',
    reportId: ReportId.LocationBarcodeReport,
    description: 'Location Barcode Report',
    parameters: {
      locationId: null,
      count: null,
      skip: null,
      showLocked: false,
    },
    permission: PermissionType.ReportLocationBarcode,
  },
  {
    id: 20,
    title: 'Inventory Event History',
    reportId: ReportId.InventoryEventHistory,
    description: 'Inventory Event History Report',
    parameters: {
      itemId: null,
      locationId: null,
      locationChildId: null,
      userId: null,
      dateRange: currentMonth,
      trackingInfo: null,
      inventoryEvents: [],
      add: false,
      move: false,
      cycle: false,
      adjustCost: false,
      ship: false,
      voidShip: false,
      receive: false,
      voidReceive: false,
      commit: false,
      voidCommit: false,
      finish: false,
      voidFinish: false,
      addTracking: false,
      removeTracking: false,
      selectAllOptional: false,
    },
    permission: PermissionType.ReportInventoryEventHistory,
  },
  {
    id: 21,
    title: 'Item Barcode Label',
    reportId: ReportId.ItemBarcodeLabel,
    description: 'Item Barcode Label Report',
    parameters: {
      purchaseOrderId: null,
      itemId: null,
      count: null,
      skip: null,
      showDescription: false,
      showPrice: false,
      showUpc: false,
      selectedAllOptional: false,
      producedFromType: null,
    },
    validation: yup.object().shape({
      purchaseOrderId: yup.number().typeError('Purchase order is required'),
    }),
    permission: PermissionType.ReportItemBarcodeLabel,
  },
  {
    id: 22,
    title: 'Item Barcode One Off',
    reportId: ReportId.ItemBarcodeOneOffReport,
    description: 'Item Barcode One Off Report',
    parameters: {
      itemId: null,
      count: null,
      skip: null,
      showDescription: false,
      showPrice: false,
      showUpc: false,
      selectedAllOptional: false,
      producedFromType: null,
      labelType: null,
      labelWidth: null,
      labelHeight: null,
    },
    permission: PermissionType.ReportItemBarcodeOneOff,
    validation: yup.object().shape({
      labelType: yup.string().typeError('Label is required'),
    }),
    clearPDFViewerWidth: true,
  },
  {
    id: 23,
    title: 'Inventory Availability',
    reportId: ReportId.InventoryAvailabilityReport,
    description: 'Inventory Availability Report',
    parameters: {
      LocationId: null,
      GroupBy: null,
      ShowLocation: false,
      IncludeZeroQuantity: false,
    },
    permission: PermissionType.ReportInventoryAvailability,
  },
  {
    id: 24,
    title: 'Vendor Fulfillment History',
    reportId: ReportId.VendorFulfillmentHistory,
    description: 'Vendor Fulfillment History Report',
    parameters: {
      vendorId: null,
      locationId: null,
      itemId: null,
      showFulfilled: false,
      showPartial: false,
      selectAllOptional: false,
      count: null,
      dateRange: null,
      endDate: null,
    },
    permission: PermissionType.ReportVendorFulFilmentHistory,
  },
  {
    id: 25,
    title: 'Margin',
    reportId: ReportId.MarginReport,
    description: 'Profit Margins Report',
    parameters: {
      dateRange: currentMonth,
      tagIds: null,
      saleItemIds: null,
      saleOrderIds: null,
      representativeIds: null,
      locationIds: null,
      customerIds: null,
      includeReturns: false,
      includeShippingItems: false,
      calculateSubtotalByItem: true,
      calculateGrandTotal: true,
      displayFooter: true,
      groupBy: 'SaleItem',
    },
    permission: PermissionType.ReportMargin,
  },
  {
    id: 26,
    title: 'Consumption',
    reportId: ReportId.ConsumptionReport,
    description: 'Consumption Report',
    parameters: {
      groupby: 'item',
      item: null,
      customer: null,
      user: null,
      class: null,
      event: null,
      location: null,
      showNote: false,
      showNoCustomerEvent: true,
      showNoClassEvent: true,
      costingMethod: null,
      ...currentMonth,
    },
    validation: yup.object().shape({
      costingMethod: yup.string().typeError('Costing Method is required'),
    }),
    permission: PermissionType.ReportConsumption,
  },
  {
    id: 27,
    title: 'Bill of Lading',
    reportId: ReportId.BOLReport,
    description: 'Bill of Lading Report',
    parameters: {
      ShipId: null,
      ThirdPartyName: null,
      ThirdPartyAddress: null,
      ThirdPartyCity: null,
      ThirdPartyState: null,
      ThirdPartyZip: null,
      ProNumber: null,
      TrailerNumber: null,
      SealNumbers: null,
      ThirdPartyCountry: null,
      SpecialInstructions: null,
      AdditionalOrderInfo: null,
      NmfcNumber: null,
      Class: null,
      HazardousMaterials: false,
      ShowProNumBarcode: false,
      ShowBolBarcode: false,
      ShowTotalWeight: false,
      ShowTotalQty: false,
    },
    validation: yup.object().shape({
      ShipId: yup.string().typeError('Ship Number is required'),
      ShowProNumBarcode: yup.boolean(),
      ProNumber: yup.string().when('ShowProNumBarcode', {
        is: true,
        then: yup.string().required().typeError('Pro Number is required'),
        otherwise: yup.string().nullable(),
      }),
    }),
    permission: PermissionType.ReportBOL,
  },
];

export const initialReportLayout: ReportLayout = {
  id: null,
  reportName: null,
  name: null,
  isDefault: false,
};

export const initialReportPreset: ReportPreset = {
  id: null,
  version: null,
  reportId: null,
  name: null,
  parameters: {},
  defaultFlag: false,
};

export const initialReportThemeColor: ReportThemeColor = {
  label: 'Initial',
  name: 'initial',
  backgroundColor: 'white',
  borderColor: '#dee2e6',
  fontColor: 'black',
  radioColor: 'black',
};

export const pickerReportThemeColor: ReportThemeColor = {
  label: 'Picker',
  name: 'picker',
  backgroundColor: 'white',
  borderColor: '#dee2e6',
  fontColor: '#D3BEAF',
  radioColor: '#D3BEAF',
};

export const initialReportTheme: ReportTheme = {
  id: null,
  layoutId: 1,
  color: initialReportThemeColor,
  font: 'arial',
  fontSize: 10,
  name: null,
};

export const reportThemeColors: ReportThemeColor[] = [
  initialReportThemeColor,
  {
    label: 'Orange',
    name: 'orange',
    backgroundColor: '#FDF4EF',
    borderColor: '#FDF4EF',
    fontColor: '#99908B',
    radioColor: '#ed9d70',
  },
  {
    label: 'Blue',
    name: 'blue',
    backgroundColor: '#DCE9F1',
    borderColor: '#DCE9F1',
    fontColor: '#78858D',
    radioColor: '#70B5E0',
  },
  {
    label: 'Green',
    name: 'green',
    backgroundColor: '#e9f8e8',
    borderColor: '#e9f8e8',
    fontColor: '#9EAD9D',
    radioColor: '#86D081',
  },
  pickerReportThemeColor,
];

export const reportFonts: ReportFontAndBackgroundColor[] = [
  { type: 'arial', title: 'Arial' },
  { type: 'verdana', title: 'Verdana' },
  { type: 'inter', title: 'Inter' },
];

export const reportBackgroundColors: ReportFontAndBackgroundColor[] = [
  { type: 'black', title: 'Black' },
  { type: 'blue', title: 'Blue' },
];

export const reportThemeFontSize: ReportFontSize[] = [
  { value: 9, title: '9pt' },
  { value: 10, title: '10pt' },
  { value: 11, title: '11pt' },
  { value: 12, title: '12pt' },
];
