export enum DateRangeOptions {
  DateCreated = 'Date Created',
  DateIssued = 'Date Issued',
  DateLastModified = 'Date Last Modified',
  DateScheduled = 'Date Scheduled',
  ItemDateScheduled = 'Item Date Scheduled',
}
export enum GroupByOptions {
  PurchaseOrder = 'Purchase Order',
  Item = 'Item',
}
export enum ShowQtyOptions {
  Recieved = 'Received',
  Remaining = 'Remaining',
  Total = 'Total',
}
