import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';

export enum InsightsEntitlementActionTypes {
  FETCH_INSIGHTS_ENTITLEMENT_START = 'insightsEntitlement/fetch_insights_entitlement_start',
  FETCH_INSIGHTS_ENTITLEMENT_SUCCESS = 'insightsEntitlement/fetch_insights_entitlement_success',
  FETCH_INSIGHTS_ENTITLEMENT_FAILURE = 'insightsEntitlement/fetch_insights_entitlement_failure',
}

const fetchInsightsEntitlementActionStart = (): StartAction => ({
  type: InsightsEntitlementActionTypes.FETCH_INSIGHTS_ENTITLEMENT_START,
  payload: null,
});

const fetchInsightsEntitlementActionSuccess = (payload: any): SuccessAction => {
  return {
    type: InsightsEntitlementActionTypes.FETCH_INSIGHTS_ENTITLEMENT_SUCCESS,
    payload,
  };
};

const fetchInsightsEntitlementActionFailure = (
  error: string
): FailureAction => ({
  type: InsightsEntitlementActionTypes.FETCH_INSIGHTS_ENTITLEMENT_FAILURE,
  payload: { error },
});

export const fetchInsightsEntitlement = () =>
  createApiAction(
    {
      hostname: process.env.REACT_APP_IDENTITY_URL,
      path: '/entitlements/ai_insights',
      method: 'GET',
    },
    fetchInsightsEntitlementActionStart,
    fetchInsightsEntitlementActionSuccess,
    fetchInsightsEntitlementActionFailure
  );
