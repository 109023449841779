import React, { memo, useCallback, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateRange } from 'materialui-daterange-picker';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { VendorAutocomplete } from 'ui/components/Autocomplete/VendorAutocomplete';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { Location } from 'services/locations';
import { Item } from 'services/items';
import { Vendor } from 'services/vendors';
import { useHandleCheckboxChange } from 'services/forms/hooks';

import { DateRangeOptions, GroupByOptions, ShowQtyOptions } from './types';
import { ReportFiltersProps } from '../../types';

const PurchaseOrderSummaryFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;

  const selectedDateRange = useMemo(() => {
    return parameters.dateRange || {};
  }, [parameters]);

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationId: location ? location.id : null,
      }));
    },
    [setParameters]
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        showBidRequest: checked,
        showFulfilled: checked,
        showIssued: checked,
        showPartial: checked,
        showVoided: checked,
        showCost: checked,
        selectAllOptional: checked,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const handleOptionsAutocomplete = useCallback(
    (optionName: string) => (e: any, option: any | null) => {
      setParameters((old) => ({ ...old, [optionName]: option }));
    },
    [setParameters]
  );

  const handleDateChange = useCallback(
    (value: DateRange) => {
      setParameters((old) => ({ ...old, dateRange: value }));
    },
    [setParameters]
  );

  const handleItemChange = useCallback(
    (selectedItem: Item | null) => {
      setParameters((old) => ({
        ...old,
        itemId: selectedItem ? selectedItem.id : null,
      }));
    },
    [setParameters]
  );

  const handleVendorChange = (vendor: Vendor | null) => {
    setParameters((old) => ({ ...old, vendorId: vendor ? vendor.id : null }));
  };
  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          sx={{ marginBottom: '8px' }}
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <Autocomplete
                label="Date Range Option"
                options={Object.values(DateRangeOptions)}
                placeholder="Select date range option"
                value={parameters.dateRangeOption || null}
                onChange={handleOptionsAutocomplete('dateRangeOption')}
                required
                fullWidth
              />
            </Box>
            <Box>
              <Autocomplete
                label="Group By"
                options={Object.values(GroupByOptions)}
                placeholder="Select Group By Option"
                value={parameters.groupBy || null}
                onChange={handleOptionsAutocomplete('groupBy')}
                required
                fullWidth
              />
            </Box>
            <Box>
              <Autocomplete
                label="Show Qty"
                options={Object.values(ShowQtyOptions)}
                placeholder="Select Quantity To Show"
                value={parameters.showQtyType || null}
                onChange={handleOptionsAutocomplete('showQtyType')}
                required
                fullWidth
              />
            </Box>
            <Box>
              <LocationsAsyncAutocomplete
                label="Location"
                value={parameters.locationId}
                placeholder="Select location"
                companyWide={false}
                onChange={handleLocationChange}
                customQuickSearchColumns={['name', 'description']}
              />
            </Box>
            <Box>
              <VendorAutocomplete
                label="Vendor"
                placeholder="Select Vendor"
                value={parameters.vendorId as number | null}
                onChange={handleVendorChange}
                required
              />
            </Box>
            <Box>
              <ItemsAutocomplete
                label="Item"
                placeholder="Select item"
                onChange={handleItemChange}
                value={parameters.itemId}
              />
            </Box>
            <DateRangePicker
              className="date-picker-small-font"
              label="Date Range"
              value={selectedDateRange}
              aria-label="Date Range"
              name="dateRange"
              placeholder="Select date range"
              onChange={handleDateChange}
              fullWidth
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showBidRequest)}
                  onChange={handleCheckboxChange}
                  color="primary"
                  name="showBidRequest"
                />
              }
              label="Bid Request"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showFulfilled)}
                  onChange={handleCheckboxChange}
                  color="primary"
                  name="showFulfilled"
                />
              }
              label="Fulfilled"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showIssued)}
                  onChange={handleCheckboxChange}
                  color="primary"
                  name="showIssued"
                />
              }
              label="Issued"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showPartial)}
                  onChange={handleCheckboxChange}
                  color="primary"
                  name="showPartial"
                />
              }
              label="Partial"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showVoided)}
                  onChange={handleCheckboxChange}
                  color="primary"
                  name="showVoided"
                />
              }
              label="Voided"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showCost)}
                  onChange={handleCheckboxChange}
                  color="primary"
                  name="showCost"
                />
              }
              label="Show Cost"
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(PurchaseOrderSummaryFilters);
