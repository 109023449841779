import { Pagination } from 'services/search';

export type DataWithTraits<T> = T & {
  isAdded: boolean;
  isEdited: boolean;
  isDeleted: boolean;
};

export interface TraitedData<T> {
  added: T[];
  edited: T[];
  deleted: T[];
}

export interface RequestConfig {
  customQuickSearchColumns?: string[];
  expands?: string[];
  quickSearchValue?: string | null;
  pagination?: Pagination;
  filter?: { [key: string]: string | number | boolean };
}
export enum ExportRouteType {
  Uoms = 'uoms',
  Carriers = 'carriers',
  Customers = 'customers',
  PurchaseOrders = 'purchase_orders',
  SalesOrders = 'sales_orders',
  Items = 'items',
  Vendors = 'vendors',
  Locations = 'locations',
  Inventory = 'inventory',
}

export enum ExportSampleType {
  InventoryExportAddSample = 'inventory-add',
  InventoryExportCycleSample = 'inventory-cycle',
}

export enum Dates {
  ReceivingDateIssued = 'purchaseOrder.dateIssued,salesOrder.dateIssued',
  DateCreated = 'dateCreated',
  DateIssued = 'dateIssued',
  DateScheduled = 'dateScheduled',
  ScheduledDate = 'scheduledDate',
  DateFullfilled = 'dateFulfilled',
  DateLastModified = 'dateLastModified',
  DateShipped = 'dateShip',
}

export enum BrowserType {
  Chrome,
  IE,
  Edge,
  Firefox,
  Safari,
  Opera,
  Undefined,
}

export interface CountryType {
  code: string;
  label: string;
  currencySymbol: string;
  currencyCode: string;
  currencyName: string;
  locale: string;
}

export enum Environment {
  TRUNK = 'TRUNK',
  QA = 'QA',
  QA_UPGRADE = 'QA-UPGRADE',
  STAGE = 'STAGE',
  PROD = 'PROD',
}

export interface PageSettings {
  salesOrder: {
    dateColumn: Dates;
  };
}

export type Timestamp = number;
export type IntervalId = ReturnType<typeof setInterval>;

export interface LongPollingProps {
  delay?: number;
}

export interface LongPollingState {
  isPolling: boolean;
  notify: boolean;
  timestamp: Timestamp;
  intervalId: IntervalId | undefined;
}
