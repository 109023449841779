import React, { memo, useCallback, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateRange } from 'materialui-daterange-picker';

import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { SaleItemAsyncAutocomplete } from 'ui/components/Autocomplete/SaleItemAsyncAutocomplete';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';
import { RepresentativeAsyncAutocomplete } from 'ui/components/Autocomplete/RepresentativeAsyncAutocomplete';
import { SalesOrderAsyncAutocomplete } from 'ui/components/Autocomplete/SalesOrderAsyncAutocomplete';
import { Customer } from 'services/customers';
import { useHandleTextFieldChange } from 'services/forms';
import { Location } from 'services/locations';
import { Representative } from 'services/representatives';
import { useHandleCheckboxChange } from 'services/forms/hooks';
import { SalesOrder } from 'services/salesOrders';
import { SaleItem } from 'services/items/saleItems';

import { groupByOptions } from './consts';
import { ReportFiltersProps } from '../../types';
import { GroupByOption } from './types';

const CustomerHistoryFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters, errors } = props;

  const selectedDateRange = useMemo(() => {
    return parameters.dateRange || {};
  }, [parameters]);

  const selectedGroupBy = useMemo(() => {
    return groupByOptions.find((o) => o.type === parameters.groupBy) || null;
  }, [parameters]);

  const handleRepresentativeChange = useCallback(
    (value: Representative | null) => {
      setParameters((old) => ({
        ...old,
        representativeId: value ? value.id : null,
      }));
    },
    [setParameters]
  );

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationId: location ? location.id : null,
      }));
    },
    [setParameters]
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        includeCreditReturnItems: checked,
        includeMiscSaleItems: checked,
        includeMiscCreditItems: checked,
        selectAllOptional: checked,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const handleGroupByChange = useCallback(
    (e: any, order: GroupByOption | null) => {
      setParameters((old) => ({ ...old, groupBy: order ? order.type : null }));
    },
    [setParameters]
  );

  const handleTextFieldChange = useHandleTextFieldChange(
    setParameters,
    parameters
  );

  const handleDateChange = useCallback(
    (value: DateRange) => {
      setParameters((old) => ({ ...old, dateRange: value }));
    },
    [setParameters]
  );

  const handleSalesOrderChange = useCallback(
    (value: SalesOrder | null) => {
      setParameters((old) => ({
        ...old,
        salesOrderId: value ? value.id : null,
      }));
    },
    [setParameters]
  );

  const handleSaleItemChange = useCallback(
    (saleItem: SaleItem | null) => {
      setParameters((old) => ({
        ...old,
        saleItemId: saleItem ? saleItem.id : null,
      }));
    },
    [setParameters]
  );

  const handleCustomerChange = useCallback(
    (customer: Customer | null) => {
      setParameters((old) => ({
        ...old,
        customerId: customer ? customer.id : null,
      }));
    },
    [setParameters]
  );
  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%" pt={1}>
            <Box>
              <CustomersAutocomplete
                label="Customer"
                onChange={handleCustomerChange}
                value={parameters.customerId}
                placeholder="Select customer"
              />
            </Box>
            <Box>
              <Autocomplete
                label="Order By"
                options={groupByOptions}
                placeholder="Group by"
                getOptionLabel={(i) => i.title}
                value={selectedGroupBy}
                onChange={handleGroupByChange}
                required
                fullWidth
              />
            </Box>
            <Box>
              <RepresentativeAsyncAutocomplete
                label="Sales Representative"
                placeholder="Select sales representative"
                value={parameters.representativeId}
                onChange={handleRepresentativeChange}
                disableAdd
              />
            </Box>
            <Box>
              <LocationsAsyncAutocomplete
                label="Location"
                value={parameters.locationId}
                placeholder="Select location"
                companyWide={false}
                onChange={handleLocationChange}
                customQuickSearchColumns={['name', 'description']}
              />
            </Box>
            <Box>
              <TextField
                className="redesign"
                variant="standard"
                label="Report Title"
                placeholder="Report title"
                name="reportTitle"
                value={parameters.reportTitle || null}
                onChange={handleTextFieldChange}
                fullWidth
              />
            </Box>
            <Box>
              <SaleItemAsyncAutocomplete
                label="Sale Item"
                placeholder="Select sale item"
                value={parameters.saleItemId}
                onChange={handleSaleItemChange}
              />
            </Box>
            <Box>
              <SalesOrderAsyncAutocomplete
                label="Sales Order Number"
                value={parameters.salesOrderId}
                onChange={handleSalesOrderChange}
                placeholder="Select Sales Order"
                required
                error={!!errors.salesOrderId}
                helperText={errors.salesOrderId}
                customQuickSearchColumns={['number']}
              />
            </Box>
            <Box>
              <DateRangePicker
                className="date-picker-small-font"
                label="Date Range"
                value={selectedDateRange}
                aria-label="Date Range"
                name="dateRange"
                placeholder="Select date range"
                onChange={handleDateChange}
                fullWidth
              />
            </Box>
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.includeCreditReturnItems)}
                  onChange={handleCheckboxChange}
                  name="includeCreditReturnItems"
                  color="primary"
                />
              }
              label="Include Credit Return Items"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.includeMiscSaleItems)}
                  onChange={handleCheckboxChange}
                  name="includeMiscSaleItems"
                  color="primary"
                />
              }
              label="Include Misc Sale Items"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.includeMiscCreditItems)}
                  onChange={handleCheckboxChange}
                  name="includeMiscCreditItems"
                  color="primary"
                />
              }
              label="Include Misc Credit Items"
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(CustomerHistoryFilters);
