import React, { memo, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateRange } from 'materialui-daterange-picker';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { Item } from 'services/items';
import { Location } from 'services/locations';

import { ReportFiltersProps } from '../../types';
import { OnHandStatus, orderByOptions } from './consts';
import { OrderByOption } from './types';

const OnHandExpirationDateFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;

  const selectedDateRange = useMemo(() => {
    return parameters.dateRange || {};
  }, [parameters]);

  const selectedOrder = useMemo(() => {
    return orderByOptions.find((o) => o.type === parameters.orderBy) ?? null;
  }, [parameters]);

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationId: location ? location.id : null,
      }));
    },
    [setParameters]
  );

  const handleItemChange = useCallback(
    (selectedItem: Item | null) => {
      setParameters((old) => ({
        ...old,
        itemId: selectedItem ? selectedItem.id : null,
      }));
    },
    [setParameters]
  );

  const handleOrderChange = useCallback(
    (_e: any, order: OrderByOption | null) => {
      setParameters((old) => ({ ...old, orderBy: order ? order.type : null }));
    },
    [setParameters]
  );

  const handleDateChange = useCallback(
    (value: DateRange) => {
      setParameters((old) => ({ ...old, dateRange: value }));
    },
    [setParameters]
  );

  const handleStatusChange = useCallback(
    (_e: any, value: OnHandStatus | null) => {
      setParameters((old) => ({ ...old, status: value ? value : null }));
    },
    [setParameters]
  );
  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <Autocomplete
                label="Order By"
                options={orderByOptions}
                placeholder="Select order"
                getOptionLabel={(i) => i.title}
                value={selectedOrder}
                onChange={handleOrderChange}
                required
                fullWidth
              />
            </Box>
            <Box>
              <Autocomplete
                label="Status"
                options={Object.values(OnHandStatus)}
                placeholder="Status"
                value={parameters.status || null}
                onChange={handleStatusChange}
                required
                fullWidth
              />
            </Box>
            <Box>
              <LocationsAsyncAutocomplete
                label="Location"
                value={parameters.locationId}
                placeholder="Select location"
                companyWide={false}
                onChange={handleLocationChange}
                customQuickSearchColumns={['name', 'description']}
              />
            </Box>
            <Box>
              <ItemsAutocomplete
                label="Item"
                placeholder="Select item"
                onChange={handleItemChange}
                value={parameters.itemId}
              />
            </Box>
            <Box>
              <DateRangePicker
                className="date-picker-small-font"
                label="Date Range"
                value={selectedDateRange}
                name="dateRange"
                placeholder="Select date range"
                onChange={handleDateChange}
                fullWidth
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(OnHandExpirationDateFilters);
