import React, { useState, useCallback, useContext, useRef } from 'react';
import { Tabs, Tab, Grid, Typography, Box } from '@mui/material';

import { CreateSalesOrderPayment } from 'services/salesOrders';
import { useCurrencyFormatter } from 'helpers';
import { Modal } from 'ui/components/Modal/Modal';
import { TabPanel } from 'ui/components/TabPanel';
import { validateYup } from 'services/forms/validation';
import { salesOrderAddNewPaymentModalValidation } from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/validations';

import {
  PaymentModalProps,
  PaymentFormValuesCheck,
  PaymentType,
  PaymentTypeId,
} from './types';
import { PaymentTabCard, PaymentTabCash, PaymentTabCheck } from './components';
import {
  MODAL_HEIGHT,
  defaultFormValuesCard,
  defaultFormValuesCheck,
} from './consts';
import { PaymentContext } from './PaymentProvider';

const PaymentModalContent: React.FC<PaymentModalProps> = (props) => {
  const { visible, onSave, onClose, processingPayment } = props;

  const currencyFormatter = useCurrencyFormatter();

  const authFormButtonRef = useRef<HTMLButtonElement | null>(null);

  const {
    shownValues,
    paymentAmount,
    setPaymentAmount,
    activePaymentType,
    setActivePaymentType,
    formValuesCard,
    setFormValuesCard,
    validationErrors,
    setValidationErrors,
  } = useContext(PaymentContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValuesCheck, setFormValuesCheck] =
    useState<PaymentFormValuesCheck>(defaultFormValuesCheck);

  const handleActiveTabChange = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: number) => {
      setActivePaymentType(newValue);
    },
    [setActivePaymentType]
  );

  const resetPaymentModalForm = useCallback(() => {
    setPaymentAmount(null);
    setFormValuesCard(defaultFormValuesCard);
    setFormValuesCheck(defaultFormValuesCheck);
  }, [setPaymentAmount, setFormValuesCard]);

  const saveCard = useCallback(() => {
    const isValid = validateYup(
      { ...formValuesCard, paymentAmount },
      salesOrderAddNewPaymentModalValidation('Card'),
      setValidationErrors
    );

    if (isValid) {
      const newPayment: CreateSalesOrderPayment = {
        paymentTypeId: PaymentTypeId.Card,
        paymentType: 'Credit/Debit',
        creditCardType: formValuesCard.cardType!,
        amount: paymentAmount!,
        lastFour: formValuesCard.lastFourDigits!,
        nameOnCard: formValuesCard.nameOnCard!,
        expirationMonth: parseInt(formValuesCard.expDate!.split('/')[0], 10),
        expirationYear: parseInt(
          '20' + formValuesCard.expDate!.split('/')[1],
          10
        ),
      };

      onSave(newPayment);
      resetPaymentModalForm();
    }
  }, [
    paymentAmount,
    resetPaymentModalForm,
    onSave,
    formValuesCard,
    setValidationErrors,
  ]);

  const saveCash = useCallback(() => {
    const isValid = validateYup(
      { paymentAmount },
      salesOrderAddNewPaymentModalValidation('Cash'),
      setValidationErrors
    );

    if (isValid) {
      const newPayment: CreateSalesOrderPayment = {
        paymentTypeId: PaymentTypeId.Cash,
        paymentType: 'Cash',
        amount: paymentAmount!,
      };

      onSave(newPayment);
      resetPaymentModalForm();
    }
  }, [paymentAmount, onSave, resetPaymentModalForm, setValidationErrors]);

  const saveCheck = useCallback(async () => {
    const isValid = validateYup(
      { ...formValuesCheck, paymentAmount },
      salesOrderAddNewPaymentModalValidation('Check'),
      setValidationErrors
    );

    if (isValid) {
      const newPayment: CreateSalesOrderPayment = {
        paymentTypeId: PaymentTypeId.Check,
        paymentType: 'Check',
        amount: paymentAmount!,
        referenceNumber: formValuesCheck.referenceNumber!,
      };

      onSave(newPayment);
      resetPaymentModalForm();
    }
  }, [
    formValuesCheck,
    paymentAmount,
    onSave,
    resetPaymentModalForm,
    setValidationErrors,
  ]);

  const handleApplyClicked = useCallback(async () => {
    setIsLoading(true);
    switch (activePaymentType) {
      case PaymentType.Card: {
        saveCard();
        break;
      }
      case PaymentType.Cash:
        await saveCash();
        break;
      case PaymentType.Check:
        await saveCheck();
        break;
    }
    setIsLoading(false);
  }, [activePaymentType, saveCard, saveCash, saveCheck]);

  const handleCloseClicked = useCallback(() => {
    resetPaymentModalForm();
    onClose();
  }, [onClose, resetPaymentModalForm]);

  return (
    <Modal
      open={visible}
      title="Payment"
      applyLabel={'Submit'}
      onApplyClicked={handleApplyClicked}
      onCancelClicked={handleCloseClicked}
      onResetClicked={resetPaymentModalForm}
      withoutDefaultPadding
      maxWidth="sm"
      customHeight={MODAL_HEIGHT}
      isLoadingContent={isLoading}
      dataQa="sale-order-payment"
      footerDivider="shadow"
    >
      <Box>
        <Tabs
          value={activePaymentType}
          onChange={handleActiveTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          className="redesign"
        >
          <Tab label="Card" data-qa="sales-order-payment-card" />
          <Tab label="Cash" data-qa="sale-order-payment-cash" />
          <Tab label="Check" data-qa="sale-order-payment-check" />
        </Tabs>
        <TabPanel value={activePaymentType} index={0} noSpacing overflow>
          <PaymentTabCard
            validationErrors={validationErrors}
            authFormButtonRef={authFormButtonRef}
          />
        </TabPanel>

        <TabPanel value={activePaymentType} index={1} noSpacing overflow>
          <PaymentTabCash
            paymentAmount={paymentAmount}
            setPaymentAmount={setPaymentAmount}
            validationErrors={validationErrors}
          />
        </TabPanel>

        <TabPanel value={activePaymentType} index={2} noSpacing overflow>
          <PaymentTabCheck
            formValues={formValuesCheck}
            setFormValues={setFormValuesCheck}
            paymentAmount={paymentAmount}
            setPaymentAmount={setPaymentAmount}
            validationErrors={validationErrors}
          />
        </TabPanel>
      </Box>
      <Box px={4} pb={4} flex="1" display="flex" alignItems="flex-end">
        <Grid container justifyContent="flex-end">
          <Grid item container spacing={2} xs={6}>
            <Grid item xs={6}>
              <Typography variant="caption">Total: </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Typography>{currencyFormatter(shownValues.total)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">Total Paid: </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Typography data-qa="sale-order-payment-total-paid">
                {processingPayment
                  ? '--'
                  : currencyFormatter(shownValues.totalPayments)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">Current Payment: </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Typography>
                {processingPayment ? (
                  '--'
                ) : (
                  <b>{currencyFormatter(paymentAmount || 0)}</b>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">Balance: </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Typography data-qa="sale-order-payment-balance">
                {processingPayment
                  ? '--'
                  : currencyFormatter(shownValues.balance)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default React.memo(PaymentModalContent);
