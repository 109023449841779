import React, { memo, useCallback, useMemo } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Box } from '@mui/system';
import { DateRange } from 'materialui-daterange-picker';
import { Customer } from 'services/customers';
import { useHandleCheckboxChange } from 'services/forms/hooks';
import { Location } from 'services/locations';
import { Representative } from 'services/representatives';
import { SalesOrder } from 'services/salesOrders';
import { Tag } from 'services/tags';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';
import LocationsAsyncAutocomplete from 'ui/components/Autocomplete/LocationsAsyncAutocomplete/LocationsAsyncAutocomplete';
import { RepresentativeAsyncAutocomplete } from 'ui/components/Autocomplete/RepresentativeAsyncAutocomplete';
import { SalesOrderAsyncAutocomplete } from 'ui/components/Autocomplete/SalesOrderAsyncAutocomplete';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { ReportFiltersProps } from '../../types';
import { GroupBy } from './Types';
import { groupByOptions } from './consts';
import { SaleItemAsyncAutocomplete } from 'ui/components/Autocomplete/SaleItemAsyncAutocomplete';
import { SaleItem } from 'services/items/saleItems';

const MarginFilter: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters, errors } = props;

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const handleLocationChange = useCallback(
    (loc: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationIds: loc ? [loc.id] : null,
      }));
    },
    [setParameters]
  );

  const handleGroupByChange = useCallback(
    (_e: any, type: GroupBy | null) => {
      setParameters((old) => ({
        ...old,
        groupBy: type ? type.type : 'SaleItem',
      }));
    },
    [setParameters]
  );

  const selectedGroupBy = useMemo(() => {
    return groupByOptions.find((o) => o.type === parameters.groupBy) || null;
  }, [parameters]);

  const handleTagChange = useCallback(
    (values: Tag[]) =>
      setParameters((old) => ({
        ...old,
        tagIds: values?.length ? values.map((t) => t.id) : null,
      })),
    [setParameters]
  );

  const handleRepresentativeChange = useCallback(
    (value: Representative | null) => {
      setParameters((old) => ({
        ...old,
        representativeIds: value ? [value.id] : null,
      }));
    },
    [setParameters]
  );

  const handleSaleItemChange = useCallback(
    (saleItem: SaleItem | null) => {
      setParameters((old) => ({
        ...old,
        saleItemIds: saleItem ? [saleItem.id] : null,
      }));
    },
    [setParameters]
  );

  const handleSalesOrderChange = useCallback(
    (value: SalesOrder | null) => {
      setParameters((old) => ({
        ...old,
        saleOrderIds: value ? [value.id] : null,
      }));
    },
    [setParameters]
  );

  const selectedDateRange = useMemo(() => {
    return parameters.dateRange || {};
  }, [parameters]);

  const handleDateChange = useCallback(
    (value: DateRange) => {
      setParameters((old) => ({ ...old, dateRange: value }));
    },
    [setParameters]
  );

  const handleCustomerChange = useCallback(
    (customer: Customer | null) => {
      setParameters((old) => ({
        ...old,
        customerIds: customer ? [customer.id] : null,
      }));
    },
    [setParameters]
  );

  return (
    <Box px={2} pb={0} pt={1}>
      <Box mb={1}>
        <Autocomplete
          label="Group By"
          options={groupByOptions}
          placeholder="Enter group by"
          getOptionLabel={(i) => i.title}
          onChange={handleGroupByChange}
          value={selectedGroupBy}
          fullWidth
          dataQa="margin-filter-group-by"
        />
      </Box>
      <Box mb={1}>
        <TagsAsyncAutocomplete
          label="Select Tag"
          placeholder="Select Tag"
          value={parameters.selectedTag}
          onChange={handleTagChange}
          disableTagsModal
          dataQa="margin-filter"
        />
      </Box>
      <Box mb={1}>
        <RepresentativeAsyncAutocomplete
          label="Sales Representative"
          placeholder="Select sales representative"
          value={
            parameters.representativeIds?.length
              ? parameters.representativeIds[0]
              : null
          }
          onChange={handleRepresentativeChange}
          disableAdd
          dataQa="margin-filter"
        />
      </Box>
      <Box mb={1}>
        <LocationsAsyncAutocomplete
          label="Location"
          placeholder="Select location"
          value={
            parameters.locationId?.length ? parameters.locationId[0] : null
          }
          onChange={handleLocationChange}
          parentId={null}
          companyWide={false}
          disableAdd
          dataQa="margin-filter"
          customQuickSearchColumns={['name', 'description']}
        />
      </Box>
      <Box mb={1}>
        <CustomersAutocomplete
          label="Customer"
          onChange={handleCustomerChange}
          value={
            parameters.customerIds?.length ? parameters.customerIds[0] : null
          }
          placeholder="Select customer"
          dataQa="margin-filter"
        />
      </Box>
      <Box mb={1}>
        <SaleItemAsyncAutocomplete
          label="Sale Item"
          placeholder="Select sale item"
          value={parameters.itemIds?.length ? parameters.itemIds[0] : null}
          onChange={handleSaleItemChange}
        />
      </Box>
      <Box mb={1}>
        <SalesOrderAsyncAutocomplete
          label="Sales Order Number"
          value={
            parameters.salesOrderIds?.length
              ? parameters.salesOrderIds[0]
              : null
          }
          onChange={handleSalesOrderChange}
          placeholder="Select Sales Order"
          required
          error={!!errors.salesOrderId}
          helperText={errors.salesOrderId}
          dataQa="margin-filter-sales-order"
          customQuickSearchColumns={['number']}
        />
      </Box>
      <Box mb={1}>
        <DateRangePicker
          className="date-picker-small-font"
          label="Date Range"
          value={selectedDateRange}
          aria-label="Date Range"
          name="dateRange"
          placeholder="Select date range"
          onChange={handleDateChange}
          fullWidth
          dataQa="margin-filter"
        />
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.includeReturns)}
            onChange={handleCheckboxChange}
            name="includeReturns"
            color="primary"
            data-qa="margin-filter-include-returns"
          />
        }
        label="Include Returns"
      />
      <FormControlLabel
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.includeShippingItems)}
            onChange={handleCheckboxChange}
            name="includeShippingItems"
            color="primary"
            data-qa="margin-filter-include-shipping-items"
          />
        }
        label="Include Shipping Items"
      />
      <FormControlLabel
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.calculateSubtotalByItem)}
            onChange={handleCheckboxChange}
            name="calculateSubtotalByItem"
            color="primary"
            data-qa="margin-filter-include-subtotals"
          />
        }
        label="Include Subtotals (by item)"
      />
      <FormControlLabel
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.calculateGrandTotal)}
            onChange={handleCheckboxChange}
            name="calculateGrandTotal"
            color="primary"
            data-qa="margin-filter-include-grand-total"
          />
        }
        label="Include Grand Total"
      />
      <FormControlLabel
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.displayFooter)}
            onChange={handleCheckboxChange}
            name="displayFooter"
            color="primary"
            data-qa="margin-filter-show-footer"
          />
        }
        label="Show footer"
      />
    </Box>
  );
};

export default memo(MarginFilter);
