import React from 'react';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';

import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { DOT_CHAR } from 'helpers';

import { InventoryItem } from 'services/inventory';

interface ItemOptionProps {
  option: InventoryItem;
}

const ItemOption: React.FC<ItemOptionProps> = (props) => {
  const { option } = props;

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box display="flex" width="100%">
        <Box flexGrow={1} minWidth={0}>
          <ImageItemBox
            name={`${option.name || ''}${
              option.sku ? ` ${DOT_CHAR} SKU: ${option.sku}` : ''
            }`}
            description={_.get(option, 'description', null)}
            source={_.get(option, 'image', null)}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width={130}
          textAlign="right"
          flexShrink={0}
        >
          <Typography variant="subtitle2" color="textSecondary">
            {option.availableLocationQuantity} {option.defaultUomAbbreviation}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(ItemOption);
