import React, {
  memo,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import moment from 'moment';
import _ from 'lodash';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { TextField } from 'ui/components/TextField/TextField';
import { ViewTrackingModal } from 'ui/components/Modal/ViewTrackingModal';
import { Carrier, CarrierService } from 'services/carriers';
import { getCustomers } from 'services/customers';
import {
  ShipItem,
  ShipCarton,
  Ship,
  createShipCartons,
  deleteShipCarton,
  updateShipItemCarton,
  getShip,
  ShipStatus,
} from 'services/shipping';
import { getOrderPriorities } from 'services/settings/orderPriorities';
import {
  replaceValueInCollection,
  removeValueFromCollection,
  useGetIntlDateFormatString,
} from 'helpers';
import { CustomField, useCustomFields } from 'services/customFields';
import { TextFieldAddress } from 'ui/components/TextField/TextFieldAddress';
import { showNotification } from 'services/api';
import {
  ShippingConnectionType,
  getValidateAddress,
  refundCarton,
} from 'services/integrations/shipping';
import {
  getShippingConnection,
  getShippingIntegrationConnection,
} from 'services/integrations/shipping/redux';
import {
  AddressValidation,
  BillToShipToAddress,
  initalValidAddress,
} from 'services/addresses';
import { getSettingsCompany } from 'services/settings/company/redux';
import { PermissionType } from 'services/permissions';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { AddressValidationModal } from 'ui/components/Modal/AddressModal/components';

import { GeneralTabProps } from './types';
import {
  SHIPPING_ITEMS_COLUMNS,
  newCarton,
  moveShipItemsOptions,
} from './consts';
import {
  ShipItemRowHeader,
  ShipItemRowSubrows,
  SplitItemModal,
} from './components';
import { ShipItemAction } from '../../types';
import { transformShipToAddress } from '../../transformations';
import { setVerifiedAddress } from './helpers';
import { logErrorCtx } from 'app/logging';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOCustomFields from 'ui/components/CustomFields/CustomFields/FBOCustomFields';
import { colorPalette } from 'ui/theme';
import FBOButtonAction from 'ui/components/Button/ButtonAction/FBOButtonAction';
import { TransitionContainer } from 'ui/modules/sales/pages/PickingPage/components/PickingDetailsCard/components/GeneralTab/styled';

const FBOGeneralTab: React.FC<GeneralTabProps> = (props) => {
  const {
    validationErrors,
    carriers,
    activeShip,
    setActiveShip,
    fetchSearchResult,
    customFieldsErrors,
    oldState,
    isLoading,
    setIsLoading,
    rowErrors,
    fetchActiveShip,
    updateShip,
    expand,
  } = props;

  const priorities = useSelector(getOrderPriorities).items;
  const customers = useSelector(getCustomers).items;
  const companySettings = useSelector(getSettingsCompany);
  const connection = useSelector(getShippingConnection);

  const setCustomFields = useCustomFields<Ship>(setActiveShip);

  const [services, setServices] = useState<CarrierService[]>([]);
  const [activeShipItemId, setActiveShipItemId] = useState<number>(-1);
  const [activeCartonId, setActiveCartonId] = useState<number>(-1);
  const [splitModalVisible, setSplitModalVisible] = useState(false);
  const [refundModalVisible, setRefundModalVisible] = useState(false);
  const [showViewTrackingModal, setShowViewTrackingModal] = useState(false);
  const [activeShipItem, setActiveShipItem] = useState<ShipItem | null>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [addressValidation, setAddressValidation] =
    useState<AddressValidation>(initalValidAddress);
  const [addressValidationModalVisible, setAddressValidationModalVisible] =
    useState<boolean>(false);

  const firstInputElement = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [maxHeight, setMaxHeight] = useState('208');
  useEffect(() => {
    if (expand) {
      const scrollHeight = containerRef.current?.scrollHeight || 500; // Adjust fallback height
      setMaxHeight(`${scrollHeight}px`);
    } else {
      setMaxHeight('0');
    }
  }, [expand]);

  const shipToAddressTransformed = transformShipToAddress(activeShip);

  const disabledFields = activeShip.status === ShipStatus.Shipped;

  const intlFormatDate = useGetIntlDateFormatString();

  const shipToAddress: BillToShipToAddress = useMemo(() => {
    return {
      name: activeShip.shipToName,
      street: activeShip.shipToStreet,
      street2: activeShip.shipToStreet2,
      city: activeShip.shipToCity,
      state: activeShip.shipToState,
      country: activeShip.shipToCountry,
      postalCode: activeShip.shipToPostalCode,
      residential: activeShip.shipToResidential,
      companyName: activeShip.shipToCompanyName,
      attention: activeShip.shipToAttention,
      verified: activeShip.shipToVerified,
    };
  }, [activeShip]);

  const customerAddresses = useMemo(() => {
    if (activeShip.salesOrder) {
      const customerId = activeShip.salesOrder.customerId;
      const addresses = _.get(
        customers.find((c) => c.id === customerId),
        'addresses',
        []
      );
      return addresses;
    }

    return [];
  }, [activeShip, customers]);

  const selectedCarrier = useMemo(
    () => carriers.find((c) => c.id === activeShip.carrierId) || null,
    [activeShip.carrierId, carriers]
  );

  const selectedCarrierService = useMemo(() => {
    if (!selectedCarrier) {
      return null;
    }
    return (
      selectedCarrier.carrierServiceList.find(
        (s) => s.id === activeShip.carrierServiceId
      ) || null
    );
  }, [selectedCarrier, activeShip.carrierServiceId]);

  const selectedPriority = useMemo(
    () => priorities.find((p) => p.id === activeShip.orderPriorityId) || null,
    [activeShip.orderPriorityId, priorities]
  );

  const activeCarton = useMemo(() => {
    return (
      activeShip.shipCartonList.find((c) => c.id === activeCartonId) || null
    );
  }, [activeCartonId, activeShip.shipCartonList]);

  useEffect(() => {
    if (activeShip.id !== null && firstInputElement.current !== null) {
      firstInputElement.current.focus();
    }
    setSelectedItems([]);
  }, [activeShip.id]);

  useEffect(() => {
    setServices(selectedCarrier ? selectedCarrier.carrierServiceList : []);
  }, [selectedCarrier]);

  const customFieldChanged = useCallback(
    (customField: CustomField) => {
      const index = activeShip.customFields.findIndex(
        (c) => c.id === customField.id
      );
      setCustomFields(
        (old) => replaceValueInCollection<CustomField>(old, customField, index)!
      );
    },
    [activeShip.customFields, setCustomFields]
  );

  const handleAutocompleteChange = useCallback(
    (name: string, defaultValue: any, value?: string) =>
      (e: React.ChangeEvent<{}>, v: any) => {
        setActiveShip({
          ...activeShip,
          [name]: v ? (value ? v[value] : v) : defaultValue,
        });
      },
    [activeShip, setActiveShip]
  );

  const handleCarrierChange = useCallback(
    (e: React.ChangeEvent<{}>, v: Carrier) => {
      setActiveShip({
        ...activeShip,
        carrierId: v ? v.id : null,
        carrierServiceId: null,
      });
    },
    [activeShip, setActiveShip]
  );

  const onSelectedItems = useCallback(
    (itemId: number) => {
      const newSelectedItems: number[] = [...selectedItems];
      const index = newSelectedItems.indexOf(itemId);
      if (index === -1) {
        newSelectedItems.push(itemId);
      } else {
        newSelectedItems.splice(index, 1);
      }
      setSelectedItems(newSelectedItems);
    },
    [selectedItems]
  );

  const handleShipToChanged = useCallback(
    (adr: BillToShipToAddress) => {
      setActiveShip({
        ...activeShip,
        shipToCity: adr.city,
        shipToStreet: adr.street,
        shipToStreet2: adr.street2,
        shipToState: adr.state,
        shipToPostalCode: adr.postalCode,
        shipToName: adr.name,
        shipToCountry: adr.country,
        shipToAttention: adr.attention,
        shipToCompanyName: adr.companyName,
        shipToResidential: adr.residential,
        shipToVerified: adr.verified,
      });
    },
    [activeShip, setActiveShip]
  );

  const showSplitModal = useCallback((shipItemId, cartonId) => {
    setSplitModalVisible(true);
    setActiveShipItemId(shipItemId);
    setActiveCartonId(cartonId);
  }, []);

  const showDeleteModal = useCallback(
    (cartonId: number) => {
      const hasShipItems = activeShip.shipItemList.some(
        (e) => e.shipCartonId === cartonId
      );
      if (hasShipItems) {
        showNotification(
          'Unable to delete ship package. It still contains Ship Items.',
          { variant: 'error' }
        );
        return;
      }
      setDeleteModalVisible(true);
      setActiveCartonId(cartonId);
    },
    [activeShip.shipItemList]
  );

  const hideSplitModal = useCallback(() => {
    setSplitModalVisible(false);
  }, []);

  const viewTracking = useCallback((shipItem: ShipItem) => {
    setActiveShipItem(shipItem);
    setShowViewTrackingModal(true);
  }, []);

  const handleViewTrackingModalClose = useCallback(() => {
    setShowViewTrackingModal(false);
    setActiveShipItem(null);
  }, []);

  const handleSetCartons = useCallback(
    (newCartons: ShipCarton[]) => {
      setActiveShip({
        ...activeShip,
        shipCartonList: newCartons,
      });
    },
    [setActiveShip, activeShip]
  );

  const handleAddNewCarton = useCallback(async () => {
    const highestCarton = _.maxBy(activeShip.shipCartonList, (c) =>
      parseInt(c.number, 10)
    );

    if (!highestCarton || activeShip.id === null) {
      return;
    }

    try {
      const saved = await updateShip();

      if (saved) {
        const newShip = await createShipCartons(activeShip.id, [
          {
            ...newCarton,
            shippingLabelUrl: null,
            number: `${parseInt(highestCarton.number, 10) + 1}`,
            weightUnit: companySettings.defaultWeightUom,
          },
        ]);

        oldState.current = newShip;
        setActiveShip({
          ...newShip,
          shipItemList: activeShip.shipItemList,
        });
      }
    } catch {
      return;
    }
  }, [
    activeShip,
    setActiveShip,
    updateShip,
    oldState,
    companySettings.defaultWeightUom,
  ]);

  // after delete is successfull and account is connected to shipping integration, we want to delete purchase label
  const handleDeleteSingle = useCallback(async () => {
    const index = activeShip.shipCartonList.findIndex(
      (i) => i.id === activeCartonId
    );
    try {
      await deleteShipCarton(activeShip.id, activeCartonId);
      const newShip = await getShip(activeShip.id || 0);
      oldState.current = newShip;
      await fetchSearchResult();
      setActiveShip((activeShip) => ({
        ...activeShip,
        shipCartonList: removeValueFromCollection(
          activeShip.shipCartonList,
          index
        ),
      }));
    } catch (e) {
      logErrorCtx('Error in deleteShipCarton', {
        error: e as Error,
        stackTrace: (e as Error).stack,
        component: 'ShippingDetailsCard GeneralTab',
        title: 'Error in deleteShipCarton',
        description: `Ship id ${activeShip.id} carton id ${activeCartonId}`,
      });
      return;
    }

    // refund
    if (connection && activeCarton && activeCarton.trackingNumber) {
      try {
        await refundCarton(connection, activeCarton.trackingNumber);
      } catch (e) {
        logErrorCtx('Error in refundCarton', {
          error: e as Error,
          stackTrace: (e as Error).stack,
          component: 'ShippingDetailsCard GeneralTab',
          title: 'Error in refundCarton',
          description: 'Error in refundCarton',
        });
      }
    }

    setDeleteModalVisible(false);
  }, [
    activeCartonId,
    fetchSearchResult,
    activeShip,
    setActiveShip,
    activeCarton,
    connection,
    oldState,
  ]);

  const moveMultipleShipItems = useCallback(
    async (cartonId: number) => {
      setIsLoading(true);
      const filteredItems = activeShip.shipItemList.filter((i) =>
        selectedItems.includes(i.id!)
      );
      try {
        await updateShipItemCarton(activeShip.id!, filteredItems, cartonId!);
        const newShip = await getShip(activeShip.id || 0);
        oldState.current = newShip;
        setActiveShip(newShip);
      } catch (e) {
        logErrorCtx('Error in updateShipItemCarton', {
          error: e as Error,
          stackTrace: (e as Error).stack,
          component: 'ShippingDetailsCard GeneralTab',
          title: 'Error in updateShipItemCarton',
          description: `Ship id ${activeShip.id} carton id ${cartonId}`,
        });
      }
      setSelectedItems([]);
      setIsLoading(false);
    },
    [activeShip, setActiveShip, selectedItems, setIsLoading, oldState]
  );

  const handleShipItemAction = useCallback(
    async (action: { type: ShipItemAction; data: any }) => {
      const { type, data } = action;

      switch (type) {
        case ShipItemAction.DeleteCarton:
          showDeleteModal(data as number);
          break;
        case ShipItemAction.RefundCarton:
          setActiveCartonId(data as number);
          setRefundModalVisible(true);
          break;
        case ShipItemAction.OpenShippingLabel:
          break;
      }
    },
    [showDeleteModal]
  );

  // we need to call delete purchase label on integration an then remove trackingNumber from carton
  const handleRefundCarton = useCallback(async () => {
    if (!connection || !activeCarton || !activeCarton.trackingNumber) {
      return;
    }

    try {
      setIsLoading(true);
      await refundCarton(connection, activeCarton.trackingNumber);
      fetchActiveShip(activeShip.id!);
    } catch (e) {
      logErrorCtx('Error in refundCarton', {
        error: e as Error,
        stackTrace: (e as Error).stack,
        component: 'ShippingDetailsCard GeneralTab',
        title: 'Error in refundCarton',
        description: `Carton tracking ${activeCarton.trackingNumber}`,
      });
    }

    setIsLoading(false);
    setRefundModalVisible(false);
  }, [connection, activeShip, fetchActiveShip, setIsLoading, activeCarton]);

  const handleAddressValidationModalClose = () => {
    setAddressValidationModalVisible(false);
  };

  const onSaveVerified = useCallback(() => {
    setVerifiedAddress(addressValidation, setActiveShip);

    setAddressValidationModalVisible(false);

    showNotification('Address validated', { variant: 'success' });
  }, [setActiveShip, addressValidation]);

  const handleVerifyClick = useCallback(async () => {
    if (!connection) {
      return;
    }

    setIsLoading(true);

    try {
      const validatedAddress = await getValidateAddress(
        connection,
        shipToAddressTransformed
      );

      if (!shipToAddress.verified) {
        setAddressValidationModalVisible(true);
      }

      setAddressValidation(validatedAddress);
    } catch (error) {
      logErrorCtx('Error in getValidateAddress', {
        error: error as Error,
        stackTrace: (error as Error).stack,
        component: 'ShippingDetailsCard GeneralTab',
        title: 'Error in getValidateAddress',
        description: 'Error in getValidateAddress',
      });
    }

    setIsLoading(false);
  }, [
    shipToAddressTransformed,
    shipToAddress.verified,
    connection,
    setIsLoading,
  ]);

  const verifiedConnection = useSelector(
    getShippingIntegrationConnection(ShippingConnectionType.Shippo)
  );

  const verifiedButtonVisible =
    !!verifiedConnection &&
    shipToAddress.name &&
    shipToAddress.country === 'US';

  return (
    <>
      <TransitionContainer
        ref={containerRef}
        expand={expand || false}
        maxHeight={maxHeight}
      >
        {expand ? (
          <Grid container spacing={2} disableEqualOverflow>
            <Grid xs={4}>
              <TextField
                className="redesign"
                variant="standard"
                readOnly
                label="Customer"
                value={_.get(activeShip, 'salesOrder.customer.name', 'N/A')}
                dataQa="shipping-customer"
              />
            </Grid>
            <Grid xs={4}>
              <TextField
                className="redesign"
                variant="standard"
                readOnly
                label="Sales Order"
                value={activeShip.number}
                dataQa="shipping-sales-order"
              />
            </Grid>
            <Grid xs={4}>
              <TextField
                className="redesign"
                variant="standard"
                readOnly
                label="Shipped"
                value={
                  activeShip.dateShip
                    ? moment(activeShip.dateShip).format(intlFormatDate)
                    : 'N/A'
                }
              />
            </Grid>
            <Grid xs={3}>
              <Autocomplete
                label="Priority"
                placeholder="Select priority"
                options={priorities}
                getOptionLabel={(p) => p.name}
                onChange={handleAutocompleteChange(
                  'orderPriorityId',
                  null,
                  'id'
                )}
                additionalInputProps={{ inputRef: firstInputElement }}
                disabled={disabledFields}
                value={selectedPriority}
                permissions={[PermissionType.ShippingEdit]}
                error={!!validationErrors.orderPriorityId}
                dataQa="shipping-priority"
              />
            </Grid>
            <Grid xs={3}>
              <Autocomplete
                label="Carrier"
                placeholder="Select carrier"
                options={carriers}
                disabled={disabledFields}
                getOptionLabel={(c) => c.name}
                permissions={[PermissionType.ShippingEdit]}
                onChange={handleCarrierChange}
                value={selectedCarrier}
                error={!!validationErrors.carrierId}
                dataQa="shipping-carrier"
              />
            </Grid>
            <Grid xs={3}>
              <Autocomplete
                label="Service"
                placeholder="Select service"
                options={services}
                disabled={disabledFields}
                getOptionLabel={(s) => s.name}
                onChange={handleAutocompleteChange(
                  'carrierServiceId',
                  null,
                  'id'
                )}
                value={selectedCarrierService}
                permissions={[PermissionType.ShippingEdit]}
                dataQa="shipping-service"
              />
            </Grid>
            <Grid xs={verifiedButtonVisible ? 2 : 3}>
              <TextFieldAddress
                label="Ship To"
                value={shipToAddress}
                permissions={[PermissionType.ShippingEdit]}
                addressChoices={customerAddresses}
                placeholder="Ship To"
                onChange={handleShipToChanged}
                disabled={disabledFields}
                dataQa="shipping-ship-to"
              />
            </Grid>
            {verifiedButtonVisible && (
              <Grid
                xs={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <>
                  {!shipToAddress.verified ? (
                    <FBOButton
                      variant="secondary"
                      color="positive"
                      size="medium"
                      onClick={handleVerifyClick}
                      disabled={isLoading}
                      fullWidth
                      data-qa="so-general-verify-address"
                    >
                      Verify
                    </FBOButton>
                  ) : (
                    <VerifiedUserIcon sx={{ color: 'green' }} />
                  )}
                </>
              </Grid>
            )}
            <Grid container xs={12}>
              <FBOCustomFields
                customFields={activeShip.customFields}
                onFieldChange={customFieldChanged}
                errors={customFieldsErrors}
                permissions={[PermissionType.ShippingEdit]}
              />
            </Grid>
          </Grid>
        ) : null}
      </TransitionContainer>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        overflow={'hidden'}
        // TODO : RESTYLING : FBOTitleBar needs "no padding" prop for inner-placements, along with border-prop
        sx={{
          borderTop: `1px solid ${colorPalette.redesign.background3}`,
        }}
      >
        <FBOTitleBar title="Items">
          {selectedItems.length > 0 && (
            <FBOButtonAction
              actionItems={moveShipItemsOptions(
                activeShip.shipCartonList,
                moveMultipleShipItems
              )}
              disabled={disabledFields}
              text="Move Items"
            />
          )}
          <FBOButton
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="shipping-add-carton"
            onClick={handleAddNewCarton}
            permissions={[PermissionType.ShippingEdit]}
            disabled={activeShip.status !== ShipStatus.Open}
          >
            Add Package
          </FBOButton>
        </FBOTitleBar>

        <ItemsTable
          columns={SHIPPING_ITEMS_COLUMNS}
          data={activeShip.shipCartonList}
          RenderCustomRow={ShipItemRowHeader}
          RenderRowSubrows={ShipItemRowSubrows}
          selectableItems={false}
          onAction={handleShipItemAction}
          setData={handleSetCartons}
          rowErrors={rowErrors}
          meta={{
            showSplitModal,
            viewTracking,
            ship: activeShip,
            onSelectedItems,
            selectedItems,
            disabledFields,
          }}
          dataQa="shipping-item-table"
          tableLayoutFixed
        />
      </Box>
      <SplitItemModal
        activeCartonId={activeCartonId}
        activeShip={activeShip}
        activeShipItemId={activeShipItemId}
        onCancelClicked={hideSplitModal}
        setActiveShip={setActiveShip}
        visible={splitModalVisible}
        onAddNewCarton={handleAddNewCarton}
        oldState={oldState}
      />
      <ViewTrackingModal
        open={showViewTrackingModal}
        trackingGroups={_.get(activeShipItem, 'trackingGroups', [])}
        itemTrackingTypes={_.get(
          activeShipItem,
          'item.itemTrackingTypeList',
          []
        )}
        onClose={handleViewTrackingModalClose}
      />
      <AddressValidationModal
        onClose={handleAddressValidationModalClose}
        modalVisible={addressValidationModalVisible}
        addressValidation={addressValidation}
        onSaveOriginal={handleAddressValidationModalClose}
        onSaveVerified={onSaveVerified}
      />
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Package"
        onConfirmClicked={handleDeleteSingle}
        onCancelClicked={() => setDeleteModalVisible(false)}
        body={'This will delete package, are you sure?'}
      />
      <ConfirmationModal
        open={refundModalVisible}
        title="Refund Package"
        onConfirmClicked={handleRefundCarton}
        onCancelClicked={() => setRefundModalVisible(false)}
        body={'This will refund package, are you sure?'}
        isLoading={isLoading}
      />
    </>
  );
};

export default memo(FBOGeneralTab);
