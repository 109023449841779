export interface PermissionGroup {
  id: number | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  name: string | null;
  permissionIds: number[];
  permissions: Permission[];
  readOnly: boolean;
  users: PermissionUser[];
  version: number | null;
}

export interface Permission {
  id: number;
  version: number | null;
  dateLastModified: Date | null;
  dateCreated: Date | null;
  key: string | null;
  name: string | null;
}

export interface PermissionCategory {
  id: number | null;
  name: string | null;
  permissionAreas: PermissionArea[];
}

export interface PermissionArea {
  id: number | null;
  key: string | null;
  name: string | null;
  permissions: Permission[];
}

export interface PermissionUser {
  id: number | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  version: number | null;
}

export enum PermissionType {
  // dashboard
  DashboardView = 'dashboard-view',

  //delete
  Undelete = 'undelete',

  // items
  ItemsView = 'items-view',
  ItemsCreate = 'items-create',
  ItemsEdit = 'items-edit',
  ItemsDelete = 'items-delete',
  ItemsModifyUom = 'items-modify-uom',
  ItemsReorderPoints = 'items-reorder-points',
  ItemsDefaultLocations = 'items-default-locations',
  ItemsVendorItems = 'items-vendor-items',
  ItemsCosting = 'items-costing',
  ItemsUpdateAverageCost = 'items-update-average-cost',

  // inventory
  InventoryView = 'inventory-view',
  InventoryAdd = 'inventory-add',
  InventoryRemove = 'inventory-remove',
  InventoryMove = 'inventory-move',
  InventoryCycle = 'inventory-cycle',
  InventoryCosting = 'inventory-costing',
  InventoryEdit = 'inventory-edit',

  // picking
  PickingCommit = 'picking-commit',
  PickingView = 'picking-view',
  PickingEdit = 'picking-edit',
  PickingFinish = 'picking-finish',

  // shipping
  ShippingEdit = 'shipping-edit',
  ShippingView = 'shipping-view',
  ShippingShip = 'shipping-ship',
  ShippingPack = 'shipping-pack',

  // customers
  CustomersView = 'customers-view',
  CustomersDelete = 'customers-delete',
  CustomersEdit = 'customers-edit',
  CustomersCreate = 'customers-create',

  // pricing-rules
  PricingRulesView = 'pricing-rules-view',
  PricinRulesEdit = 'pricing-rules-edit',
  PricinRulesCreate = 'pricing-rules-create',
  PricinRulesDelete = 'pricing-rules-delete',

  // sales-order
  SalesOrderIssue = 'sales-orders-issue',
  SalesOrderEdit = 'sales-orders-edit',
  SalesOrderDelete = 'sales-orders-delete',
  SalesOrderView = 'sales-orders-view',
  SalesOrderCreate = 'sales-orders-create',
  SalesOrderPayment = 'sales-orders-payment',
  SalesOrderOverridePrice = 'sales-orders-override-price',

  // vendors
  VendorsView = 'vendors-view',
  VendorsCreate = 'vendors-create',
  VendorsDelete = 'vendors-delete',
  VendorsEdit = 'vendors-edit',
  VendorsOverrideVendorMinimum = 'vendors-override-vendor-minimum',

  // purchase-order
  PurchaseOrderView = 'purchase-orders-view',
  PurchaseOrderCreate = 'purchase-orders-create',
  PurchaseOrderEdit = 'purchase-orders-edit',
  PurchaseOrderIssue = 'purchase-orders-issue',
  PurchaseOrderOverrideCost = 'purchase-orders-override-cost',
  PurchaseOrderDelete = 'purchase-orders-delete',
  PurchaseOrderPayment = 'purchase-orders-payment',

  // receiving
  ReceivingView = 'receiving-view',
  ReceivingReceive = 'receiving-receive',
  ReceivingReconcile = 'receiving-reconcile',
  ReceivingModifyItemLocation = 'receiving-modify-receipt-item-location',
  ReceivingEdit = 'receiving-edit',
  ReceivingItemReconcile = 'receiving-item-reconcile',
  ReceivingItemReject = 'receiving-item-reject',
  ReceivingItemFulfill = 'receiving-item-fulfill',
  ReceivingReject = 'receiving-reject',
  ReceivingFulfill = 'receiving-fulfill',
  ReceivingItemReceive = 'receiving-item-receive',

  // UOMs
  UomView = 'uom-view',
  UomEdit = 'uom-edit',
  UomCreate = 'uom-create',
  UomDelete = 'uom-delete',

  // locations
  LocationView = 'locations-view',
  LocationEdit = 'locations-edit',
  LocationsCreate = 'locations-create',
  LocationsDelete = 'locations-delete',

  // users
  UserView = 'users-view',
  UsersCreate = 'users-create',
  UsersEdit = 'users-edit',
  UsersDelete = 'users-delete',

  // permissions
  PermissionView = 'permission-groups-view',
  PermissionEdit = 'permission-groups-edit',
  PermissionCreate = 'permission-groups-create',
  PermissionDelete = 'permission-groups-delete',

  // payment terms
  PaymentTermsView = 'payment-terms-view',
  PaymentTermsEdit = 'payment-terms-edit',
  PaymentTermsCreate = 'payment-terms-create',
  PaymentTermsDelete = 'payment-terms-delete',

  // currency
  CurrencyView = 'setup-currency-view',
  CurrencyEdit = 'setup-currency-edit',
  CurrencyCreate = 'setup-currency-create',
  CurrencyDelete = 'setup-currency-delete',

  // representatives
  RepresentativesView = 'representatives-view',
  RepresentativesCreate = 'representatives-create',
  RepresentativesEdit = 'representatives-edit',
  RepresentativesDelete = 'representatives-delete',

  // tax rates
  TaxRatesView = 'tax-rates-view',
  TaxRatesEdit = 'tax-rates-edit',
  TaxRatesCreate = 'tax-rates-create',
  TaxRatesDelete = 'tax-rates-delete',

  // carriers
  CarriersView = 'carriers-view',
  CarriersCreate = 'carriers-create',
  CarriersEdit = 'carriers-edit',
  CarriersDelete = 'carriers-delete',

  // settings
  SettingsCompanyView = 'settings-company-view',
  SettingsCompanyEdit = 'settings-company-edit',
  SettingsSalesOrderView = 'settings-sales-order-view',
  SettingsSalesOrderEdit = 'settings-sales-order-edit',
  SettingsPickingView = 'settings-picking-view',
  SettingsPickingEdit = 'settings-picking-edit',
  SettingsPurchaseOrderView = 'settings-purchase-order-view',
  SettingsPurchaseOrderEdit = 'settings-purchase-order-edit',
  SettingsTrackingView = 'settings-tracking-view',
  SettingsTrackingEdit = 'settings-tracking-edit',
  SettingsEmailingView = 'settings-emailing-view',
  SettingsEmailingEdit = 'settings-emailing-edit',
  SettingsShippingView = 'settings-shipping-view',
  SettingsShippingEdit = 'settings-shipping-edit',
  SettingsAccountMappingView = 'settings-account-mapping-view',
  SettingsAccountMappingEdit = 'settings-account-mapping-edit',
  SettingsMultiCurrencyView = 'settings-multicurrency-view',
  SettingsMultiCurrencyEdit = 'settings-multicurrency-edit',

  // reports
  ReportVendorFulFilmentHistory = 'reports-vendor-fulfilment-history',
  ReportSalesOrder = 'reports-sales-order',
  ReportReceivingList = 'reports-receiving-list',
  ReportItemsPriceList = 'reports-item-price-list',
  ReportCycleCount = 'reports-cycle-count',
  ReportPurchaseOrder = 'reports-purchase-order',
  ReportPick = 'reports-pick',
  ReportCustomerHistory = 'reports-customer-history',
  ReportTotalItemSales = 'reports-total-item-sales',
  ReportReorder = 'reports-reorder',
  ReportShippingInvoice = 'reports-shipping-invoice',
  ReportInventoryValuationSummary = 'reports-inventory-valuation-summary',
  ReportPackingList = 'reports-packing-list',
  ReportInventoryOnHand = 'reports-inventory-on-hand',
  ReportItemBarcodeLabel = 'reports-item-barcode-label',
  ReportMargin = 'reports-margin',
  ReportConsumption = 'reports-consumption',
  ReportItemPriceList = 'reports-item-price-list',
  ReportBarcode = 'reports-barcode',
  ReportInventoryAvailability = 'reports-inventory-availability',
  ReportInventoryEventHistory = 'reports-inventory-event-history',
  ReportItemBarcodeOneOff = 'reports-item-barcode-one-off',
  ReportLocationBarcode = 'reports-location-barcode',
  ReportOnHandByExpiration = 'reports-on-hand-by-expiration',
  ReportSalesOrderSummary = 'reports-sales-order-summary',
  ReportPurchaseOrderSummary = 'reports-purchase-order-summary',
  ReportBOL = 'reports-bol',

  // ai insights
  AiInsightsViewEdit = 'ai-insights-view-edit-ai-insights',

  // accounting
  AccountingView = 'accounting-view',
  AccountingEdit = 'accounting-edit',

  // integrations
  IntegrationsShipping = 'integrations-shipping',
  IntegrationsECommerce = 'integrations-e-commerce',
  IntegrationsPayments = 'integrations-payments',
}
