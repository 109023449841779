import { RequestConfig, defaultMaximumPagination } from 'helpers';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';

import {
  transformLocation,
  transformLocationRequest,
  transformLocationToSort,
} from './transformations';
import { Location } from './types';
import { LocationType } from './consts';
import { paginatedApiCallNewStandard2 } from 'services/api/createApiCall';

export const fetchLocationsAPI = async (
  config: RequestConfig,
  locationTypes: LocationType[] = []
): Promise<DataWithPagination<Location>> => {
  const {
    pagination = defaultMaximumPagination,
    expands = ['address'],
    customQuickSearchColumns = ['name', 'description', 'path'],
    quickSearchValue,
    filter,
  } = config;

  let path = `/v1/locations?expand=${expands.join(',')}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  if (locationTypes.length) {
    path += `&type=${locationTypes}`;
  }

  // add filter to path
  if (filter) {
    Object.keys(filter).forEach((key) => {
      path += `&${key}=${filter[key]}`;
    });
  }

  const res = await paginatedApiCall(path, pagination, transformLocation);

  return res;
};

export const fetchChildLocationsAPI = async (
  config: RequestConfig,
  parentLocationId: number
) => {
  const {
    pagination = defaultMaximumPagination,
    expands = [],
    customQuickSearchColumns = ['name', 'description', 'path'],
    quickSearchValue,
  } = config;

  let path = `/v1/locations/${parentLocationId}/children?expand=${expands.join(
    ','
  )}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  const res = await paginatedApiCall(path, pagination, transformLocation);

  return res;
};

export const fetchLocation = async (id: number): Promise<Location> => {
  const response = await createApiCall(
    {
      path: `/v1/locations/${id}?expand=address`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformLocation(response.data);
};

export const fetchLocationsByIds = async (ids: string): Promise<Location[]> => {
  const response = await createApiCall(
    {
      path: `/v1/locations?id=${ids}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return response.data.results;
};

/**
 * Fetches all root locations. This function paginates the locations API until
 * all locations are fetched.
 *
 * @returns A promise that resolves to an array of Location objects.
 */
export const fetchAllRootLocations = async (): Promise<Location[]> => {
  let allLocations: Location[] = [];
  let page = 1;
  const pageSize = 100;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const response = await createApiCall(
      {
        path: `/v1/locations?root=true&pageNumber=${page}&pageSize=${pageSize}`,
        method: 'GET',
      },
      {
        getMessage: {
          error: (error: any) => `${error.response.data.message}`,
        },
      }
    )();

    const locations = response.data.results;
    allLocations = [...allLocations, ...locations];

    if (locations.length < pageSize) {
      break; // Exit loop if fewer than pageSize locations are returned
    }

    page++;
  }

  return allLocations;
};

export const postLocation = async (location: Location): Promise<Location> => {
  const response = await createApiCall(
    {
      path: `/v1/locations?expand=address`,
      method: 'POST',
      body: transformLocationRequest(location),
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'New location created',
      },
    }
  )();

  return transformLocation(response.data);
};

export const putLocation = async (location: Location): Promise<Location> => {
  const response = await createApiCall(
    {
      path: `/v1/locations?expand=address`,
      method: 'PUT',
      body: transformLocationRequest(location),
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Location updated',
      },
    }
  )();

  return transformLocation(response.data);
};

export const deleteLocation = async (id: number): Promise<boolean> => {
  await createApiCall(
    {
      path: `/v1/locations/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Location deleted',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return true;
};

export const sortLocations = async (locations: Location[]): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/locations/sort`,
      method: 'POST',
      body: locations.map(transformLocationToSort),
    },
    {
      getMessage: {
        success: () => 'Locations sorted',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const restoreLocation = async (locationId: number) => {
  await createApiCall(
    {
      path: `/v1/locations/${locationId}/restore`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Location successfully restored',
      },
    }
  )();
};
export const fetchLocationsByItemAPI = async (
  config: RequestConfig,
  itemId: number
): Promise<DataWithPagination<Location>> => {
  const {
    pagination = defaultMaximumPagination,
    quickSearchValue,
    customQuickSearchColumns = ['name'],
  } = config;

  let path = `/inventory-mgmt/v1/pick-locations/${itemId}`;

  if (quickSearchValue) {
    path += `?search.cols=${customQuickSearchColumns}&search.like=${quickSearchValue}`;
  }

  try {
    const res = await paginatedApiCallNewStandard2(
      path,
      pagination,
      transformLocation
    );

    return res;
  } catch (error) {
    console.error('Error fetching locations:', error);
    throw new Error('Failed to fetch locations');
  }
};
