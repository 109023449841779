import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  fetchUsers,
  getSearches,
  fetchSearch,
  USERS_COLUMNS,
  transformUser,
  User,
} from 'services/userV2';
import {
  fetchAllRootLocations,
  fetchLocations,
  getLocations,
  Location,
} from 'services/locations';
import { Pagination } from 'services/search';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { useUrlQueryObject } from 'services/url';
import { fetchSettingsCompanies } from 'services/settings/company/redux';
import { Dates } from 'helpers';

import { Routes } from '../../navigation';
import {
  UsersSearchResults,
  UserDetailsCard,
  UsersAdvancedSearch,
} from './components';
import { UsersPageCmp } from './types';
import {
  advancedSearchReduxActions,
  displayNameMap,
  initialUsersFormValues,
  initialPagination,
  createDisplayValueMap,
  UsersPageAction,
} from './consts';

const UsersPageV2: UsersPageCmp = (props) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeUserId,
  } = props;

  const { items: locations } = useSelector(getLocations);

  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);
  const [rootLocations, setRootLocations] = useState<Location[]>([]);
  const [, extendUrlQuery] = useUrlQueryObject();

  const onActiveUserClose = useCallback(
    () => extendUrlQuery({ activeId: null }),
    [extendUrlQuery]
  );

  const handleAddNewPress = useCallback(() => {
    extendUrlQuery({ activeId: -1 });
  }, [extendUrlQuery]);

  const handleUserClicked = (id: number | null) =>
    extendUrlQuery({ activeId: id });

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  const handlePageAction = useCallback(
    (action: UsersPageAction, date: Dates) => {
      if (action === UsersPageAction.ChangeDate) {
        setActiveDate(date);
      }
    },
    []
  );

  useEffect(() => {
    const getRootLocations = async () => {
      try {
        const rootLocations = await fetchAllRootLocations();
        setRootLocations(rootLocations);
      } catch (e) {
        console.error(e);
      }
    };
    getRootLocations();
  }, []);

  return (
    <PageWithAdvancedSearch
      detailCardColumns={USERS_COLUMNS}
      initialFormValues={initialUsersFormValues}
      advancedSearchReduxActions={advancedSearchReduxActions}
      searchResult={searchResult}
      fetchSearchResult={fetchSearchResult}
      AdvancedSearchFieldsCmp={UsersAdvancedSearch}
      displayNameMap={displayNameMap}
      displayValueMap={createDisplayValueMap(locations)}
      pageName="Users"
    >
      <PaperSlidingLayout shown={Boolean(activeUserId)}>
        <UsersSearchResults
          users={searchResult.items}
          activeUserId={activeUserId}
          activeDate={activeDate}
          onPageAction={handlePageAction}
          handleUserClick={handleUserClicked}
          onAddNewPress={handleAddNewPress}
          isLoadingUsers={isLoadingSearchResult}
          pagination={searchResult.pagination || initialPagination}
          onPaginationChange={handlePaginationChange}
        />
        <UserDetailsCard
          activeUserId={activeUserId}
          onClose={onActiveUserClose}
          fetchSearchResult={fetchSearchResult}
          rootLocations={rootLocations}
        />
      </PaperSlidingLayout>
    </PageWithAdvancedSearch>
  );
};

UsersPageV2.route = Routes.UsersPage;

export default withSearchResults<User>(UsersPageV2, {
  url: '/user-mgmt/v1/users',
  dataAdapter: transformUser,
  initialPagination,
  quickSearchColumns: [
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'phoneType',
  ],
  columns: USERS_COLUMNS,
  getSearches,
  fetchSearch,
  rehydrationThunks: [fetchLocations, fetchUsers, fetchSettingsCompanies],
});
