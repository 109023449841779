import * as yup from 'yup';

export const yupReorderPointSchema = yup.object().shape({
  id: yup.number().nullable(true),
  version: yup.number().nullable(true),
  location: yup.object().required(),
  reorderPointAlerts: yup.array().nullable(true),
  min: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string' && originalValue.trim() !== '') {
        const parsed = Number(originalValue);
        return isNaN(parsed) ? undefined : parsed;
      }
      return isNaN(value) ? undefined : value;
    })
    .moreThan(0, 'Minimum value must be a positive number')
    .test(
      'is-number',
      'Minimum value must be a valid number',
      function (value) {
        return value !== undefined && value !== null && !isNaN(value);
      }
    )
    .required('Minimum value is required'),
  max: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string' && originalValue.trim() !== '') {
        const parsed = Number(originalValue);
        return isNaN(parsed) ? undefined : parsed;
      }
      return isNaN(value) ? undefined : value;
    })
    .required('Maximum value is required')
    .test(
      'max-gte-min',
      'Maximum value must be greater than or equal to minimum value',
      function (value) {
        const { min } = this.parent;
        return (
          min === undefined || min === null || value == null || value >= min
        );
      }
    )
    .moreThan(0, 'Maximum value must be a positive number'),

  alert: yup.number().nullable(true),
  dailyConsumptionRate: yup.number().nullable(true),
  locationId: yup.number().required(),
  itemId: yup.number().nullable(true),
  deleted: yup.boolean().nullable(true),
});
