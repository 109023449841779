import React, { memo } from 'react';
import AutoComplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { Pagination } from 'services/search';
import { initialPagination } from 'ui/components/Table/ItemsTable';
import { PaginationDirection } from 'services/url';
import {
  useAsyncAutocomplete,
  useScrollPosition,
} from '../../../../../../../components/Autocomplete/Autocomplete/hooks';
import ItemOption from '../../../../../../../components/Autocomplete/ItemsAutocomplete/ItemOption';
import { resolvedFetchItemsForBulkMoveFunction } from './helpers';
import { InventoryItem } from 'services/inventory';
import { InventoryItemsAutocompleteProps } from './types';

const initialInventoryItemPagination: Pagination = {
  ...initialPagination,
  pageSize: 50,
  sort: { sortBy: 'name', direction: PaginationDirection.Ascending },
};

const QuickTransferItemsAutocomplete: React.FC<
  InventoryItemsAutocompleteProps
> = (props) => {
  const {
    label,
    value,
    placeholder,
    required,
    onChange,
    additionalInputProps,
    dataQa,
    error,
    disabled = false,
    locationId = null,
  } = props;

  const {
    data: items,
    isLoading,
    innerValue,
    open,
    onCloseAutocomplete,
    onNextPage,
    onOpenAutocomplete,
    setSearchValue,
    setInnerValue,
  } = useAsyncAutocomplete(
    value,
    resolvedFetchItemsForBulkMoveFunction(locationId),
    null,
    initialInventoryItemPagination
  );

  const { listRef, captureScrollPosition } = useScrollPosition(items);

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      if (!val) {
        onOpenAutocomplete();
        setInnerValue(null);
      }
      setSearchValue(val || null);
      onOpenAutocomplete();
      return;
    }

    if (reason === 'clear') {
      onChange(null);
    }

    setSearchValue(null);
  };

  const handleChange = (e: any, item: InventoryItem | null) => {
    setInnerValue(item);
    onChange(item);
  };

  return (
    <AutoComplete
      className={'redesign'}
      options={items}
      open={open}
      onOpen={onOpenAutocomplete}
      onClose={onCloseAutocomplete}
      disabled={disabled}
      onChange={handleChange}
      onInputChange={handleAutocompleteInputChange}
      getOptionLabel={(option: InventoryItem) => (option ? option.name : '')}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      renderOption={(ItemsAutocompleteProps, option: InventoryItem) => (
        <li {...ItemsAutocompleteProps} key={option.id}>
          <ItemOption option={option} />
        </li>
      )}
      value={innerValue}
      noOptionsText={isLoading ? 'Loading...' : 'No options'}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const node = event.currentTarget;
          if (
            Math.ceil(node.scrollTop + node.clientHeight) === node.scrollHeight
          ) {
            onNextPage();
          }
        },
        // @ts-ignore
        ref: listRef,
        onScrollCapture: captureScrollPosition,
      }}
      renderInput={(params) => {
        // params.InputProps.endAdornment is component with 2 children
        // first child is clear button, so we want to set it to null
        const endAdornment = params.InputProps.endAdornment as any;

        return (
          <TextField
            // we are omitting props that would overwrite our styling in TextField
            {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
            label={label}
            placeholder={placeholder}
            error={error}
            required={required}
            InputProps={{
              ...params.InputProps,
              ...additionalInputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {endAdornment}
                </React.Fragment>
              ),
            }}
            inputProps={{
              'data-qa': dataQa,
              ...params.inputProps,
            }}
          />
        );
      }}
      clearIcon={
        <CloseIcon
          fontSize="small"
          data-qa={`${dataQa}-autocomplete-clear-icon`}
        />
      }
      popupIcon={
        <ArrowDropDownIcon
          fontSize="small"
          data-qa={`${dataQa}-autocomplete-dropdown-icon`}
        />
      }
    />
  );
};

export default memo(QuickTransferItemsAutocomplete);
