import React from 'react';
import { Switch } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes } from './routes';
import { ReportsPage } from '../pages';
import { ReportsRouterCmp, ReportsRouterProps } from './types';
import { InsightsPage } from '../pages/InsightsPage';
import { PermissionType } from 'services/permissions';
import { useSelector } from 'react-redux';
import { getInsightsEntitlement } from 'services/insights';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReportsRouter: ReportsRouterCmp = (props: ReportsRouterProps) => {
  const insightEntitlement = useSelector(getInsightsEntitlement);
  return (
    <Switch>
      <SecureRoute path={ReportsPage.route} exact component={ReportsPage} />
      <SecureRoute
        path={InsightsPage.route}
        exact
        redirectToHome={!insightEntitlement}
        component={InsightsPage}
        permissions={[PermissionType.AiInsightsViewEdit]}
        featureFlag="aiInsights"
      />
    </Switch>
  );
};

ReportsRouter.route = Routes.ReportsRouter;

export default ReportsRouter;
