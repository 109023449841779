import { Errors, Fields, validateYup } from 'services/forms/validation';
import { yupReorderPointSchema } from './validations';

export const isReorderPointValidAndSetErrors = (
  fields: Fields,
  setErrors: React.Dispatch<React.SetStateAction<Errors>>
) => {
  const notValidFields = !validateYup(fields, yupReorderPointSchema, setErrors);

  if (notValidFields) {
    return false;
  }

  return true;
};
