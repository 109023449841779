import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import AutoComplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { activeUserHasPermission } from 'services/user/redux';
import { Pagination } from 'services/search';
import { initialPagination } from 'ui/components/Table/ItemsTable';
import { PaginationDirection } from 'services/url';

import { AccountsAutocompleteProps } from './types';
import {
  accountsAutocompleteOptionLabel,
  accountsAutocompleteFilterOptions,
  resolvedFetchAccountsFunction,
} from './helpers';
import { useAsyncAutocomplete, useScrollPosition } from '../Autocomplete/hooks';
import { getAccountById } from 'services/accounting/api';
import { Account } from 'services/accounting';

const initialAccountsPagination: Pagination = {
  ...initialPagination,
  pageSize: 25,
  sort: { sortBy: 'name', direction: PaginationDirection.Ascending },
};

const AccountsAutocomplete: React.FC<AccountsAutocompleteProps> = (props) => {
  const {
    onChange,
    dataQa,
    permissions = [],
    disabled,
    label,
    value,
    placeholder,
    error,
    required,
    additionalInputProps,
    expands,
    customQuickSearchColumns,
    helperText,
  } = props;

  const {
    data: accounts,
    isLoading,
    innerValue,
    open,
    onCloseAutocomplete,
    onNextPage,
    onOpenAutocomplete,
    setSearchValue,
    setInnerValue,
  } = useAsyncAutocomplete(
    value,
    resolvedFetchAccountsFunction(expands, customQuickSearchColumns),
    getAccountById,
    initialAccountsPagination
  );

  const canEdit = useSelector(activeUserHasPermission(permissions));
  const { listRef, captureScrollPosition } = useScrollPosition(accounts);

  const isDisabled = disabled || !canEdit;

  const handleAutocompleteInputChange = (
    _e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      if (!val) {
        onOpenAutocomplete();
        setInnerValue(null);
      }
      setSearchValue(val || null);
      onOpenAutocomplete();
      return;
    }

    if (reason === 'clear') {
      onChange(null);
    }

    setSearchValue(null);
  };

  const handleChange = (_e: any, account: Account | null) => {
    setInnerValue(account);
    onChange(account);
  };

  return (
    <>
      <AutoComplete
        // TODO : RESTYLING : minor style adjustment needed for now until we use the FBOAutoSelect component
        style={{ marginTop: '0px', marginBottom: '0px' }}
        className="redesign"
        options={accounts}
        open={open}
        disabled={isDisabled}
        value={innerValue}
        onOpen={onOpenAutocomplete}
        onClose={onCloseAutocomplete}
        onChange={handleChange}
        onInputChange={handleAutocompleteInputChange}
        getOptionLabel={accountsAutocompleteOptionLabel}
        filterOptions={accountsAutocompleteFilterOptions}
        noOptionsText={isLoading ? 'Loading...' : 'No options'}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const node = event.currentTarget;
            if (
              Math.ceil(node.scrollTop + node.clientHeight) ===
              node.scrollHeight
            ) {
              onNextPage();
            }
          },
          // @ts-ignore
          ref: listRef,
          onScrollCapture: captureScrollPosition,
        }}
        renderInput={(params) => {
          // params.InputProps.endAdornment is component with 2 children
          // first child is clear button, so we want to set it to null
          const endAdornment = params.InputProps.endAdornment as any;

          return (
            <TextField
              className="redesign"
              // we are omitting props that would overwrite our styling in TextField
              {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
              label={label}
              placeholder={placeholder}
              error={error}
              required={required}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                ...additionalInputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {endAdornment}
                  </React.Fragment>
                ),
              }}
              inputProps={{
                'data-qa': dataQa,
                'data-lpignore': 'true',
                ...params.inputProps,
              }}
            />
          );
        }}
        clearIcon={
          dataQa && (
            <CloseIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-clear-icon`}
            />
          )
        }
        popupIcon={
          dataQa && (
            <ArrowDropDownIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-dropdown-icon`}
            />
          )
        }
      />
    </>
  );
};

export default memo(AccountsAutocomplete);
