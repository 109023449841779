import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATUS,
} from 'services/api';
import { combineReducers } from 'redux';

import { InsightsEntitlementActionTypes } from './actions';

const initialState = {
  _status: INITIAL_API_STATUS,
  entitlement: false,
};

const insightsEntitlementReducer = createApiReducer(
  [
    InsightsEntitlementActionTypes.FETCH_INSIGHTS_ENTITLEMENT_START,
    InsightsEntitlementActionTypes.FETCH_INSIGHTS_ENTITLEMENT_SUCCESS,
    InsightsEntitlementActionTypes.FETCH_INSIGHTS_ENTITLEMENT_FAILURE,
  ],
  (data) => ({ entitlement: data }),
  (data) => data
);

const insightsEntitlementChainedReducers = chainReducers(
  initialState,
  insightsEntitlementReducer
);

export default combineReducers({
  insightsEntitlement: insightsEntitlementChainedReducers,
});
