import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import AutoComplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { activeUserHasPermission } from 'services/user/redux';
import { Pagination } from 'services/search';
import { initialPagination } from 'ui/components/Table/ItemsTable';
import { PaginationDirection } from 'services/url';

import { ClassAutocompleteProps } from './types';
import {
  resolvedFetchClassesFunction,
  classAutocompleteOptionLabel,
  classAutocompleteFilterOptions,
} from './helpers';
import { useAsyncAutocomplete, useScrollPosition } from '../Autocomplete/hooks';
import { getClassById } from 'services/classes/api';
import NewClassModal from './NewClassModal';
import { Class } from 'services/classes';

const initialClassPagination: Pagination = {
  ...initialPagination,
  pageSize: 50,
  sort: { sortBy: 'name', direction: PaginationDirection.Ascending },
};

const ClassAutocomplete: React.FC<ClassAutocompleteProps> = (props) => {
  const {
    onChange,
    dataQa,
    permissions = [],
    disabled,
    label,
    value,
    placeholder,
    error,
    required,
    disableAdd = false,
    additionalInputProps,
    expands,
    customQuickSearchColumns,
    helperText,
  } = props;

  const {
    data: classes,
    isLoading,
    innerValue,
    open,
    onCloseAutocomplete,
    onNextPage,
    onOpenAutocomplete,
    setSearchValue,
    setInnerValue,
    setData,
  } = useAsyncAutocomplete(
    value,
    resolvedFetchClassesFunction(expands, customQuickSearchColumns),
    getClassById,
    initialClassPagination
  );

  const [showModal, setShowModal] = useState(false);
  const [newClassName, setNewClassName] = useState<string | null>(null);

  const canEdit = useSelector(activeUserHasPermission(permissions));
  const { listRef, captureScrollPosition } = useScrollPosition(classes);

  const isDisabled = disabled || !canEdit;

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      if (!val) {
        onOpenAutocomplete();
        setInnerValue(null);
      }
      setSearchValue(val || null);
      onOpenAutocomplete();
      return;
    }

    if (reason === 'clear') {
      onChange(null);
    }

    setSearchValue(null);
  };

  const handleChange = (e: any, accountingClass: Class | null) => {
    if (
      accountingClass &&
      (accountingClass.id === null || accountingClass.id < 0)
    ) {
      setNewClassName(accountingClass.name);
      setShowModal(true);
      return;
    }
    setInnerValue(accountingClass);
    onChange(accountingClass);
  };

  const handleSaveModal = useCallback(
    (accountingClass: Class) => {
      setShowModal(false);
      setData([accountingClass]);
      setInnerValue(accountingClass);
      onChange(accountingClass);
    },
    [onChange, setData, setInnerValue]
  );

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <AutoComplete
        // TODO : RESTYLING : minor style adjustment needed for now until we use the FBOAutoSelect component
        style={{ marginTop: '0px', marginBottom: '0px' }}
        className={'redesign'}
        options={classes}
        open={open}
        disabled={isDisabled}
        value={innerValue}
        onOpen={onOpenAutocomplete}
        onClose={onCloseAutocomplete}
        onChange={handleChange}
        onInputChange={handleAutocompleteInputChange}
        getOptionLabel={classAutocompleteOptionLabel}
        filterOptions={classAutocompleteFilterOptions(disableAdd)}
        noOptionsText={isLoading ? 'Loading...' : 'No options'}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const node = event.currentTarget;
            if (
              Math.ceil(node.scrollTop + node.clientHeight) ===
              node.scrollHeight
            ) {
              onNextPage();
            }
          },
          // @ts-ignore
          ref: listRef,
          onScrollCapture: captureScrollPosition,
        }}
        renderInput={(params) => {
          // params.InputProps.endAdornment is component with 2 children
          // first child is clear button, so we want to set it to null
          const endAdornment = params.InputProps.endAdornment as any;

          return (
            <TextField
              // we are omitting props that would overwrite our styling in TextField
              {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
              className={'redesign'}
              label={label}
              placeholder={placeholder}
              error={error}
              required={required}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                ...additionalInputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {endAdornment}
                  </React.Fragment>
                ),
              }}
              inputProps={{
                'data-qa': dataQa,
                'data-lpignore': 'true',
                ...params.inputProps,
              }}
            />
          );
        }}
        clearIcon={
          dataQa && (
            <CloseIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-clear-icon`}
            />
          )
        }
        popupIcon={
          dataQa && (
            <ArrowDropDownIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-dropdown-icon`}
            />
          )
        }
      />

      <NewClassModal
        show={showModal}
        newClassName={newClassName}
        onSave={handleSaveModal}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default memo(ClassAutocomplete);
