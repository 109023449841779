import React, { useMemo, memo, useCallback, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

import { MenuItem } from 'ui/components/Menu';
import { IconNames, SupportColors } from 'ui/theme';
import { TextFieldWithActions } from 'ui/components/TextField/TextFieldWithActions';
import {
  Address,
  BillToShipToAddress,
  transformAddressToBillToShipToAddress,
  initialBillToShipToAddress,
} from 'services/addresses';

import AddressModal from './AddressModal';
import { TextFieldAddressProps } from './types';

const CustomClearIcon: React.FC = () => (
  <ClearIcon style={{ color: SupportColors.iconOnLight }} />
);

const TextFieldAddress: React.FC<TextFieldAddressProps> = (
  props: TextFieldAddressProps
) => {
  const {
    value,
    addressChoices,
    placeholder,
    label,
    additionalActionItems = [],
    onChange,
    dataQa,
    disabled = false,
    permissions = [],
    customerId = null,
    vendorId = null,
  } = props;

  const [modalVisible, setModalVisible] = useState(false);

  const fullAddressExists: boolean = useMemo(
    () =>
      !!(
        value.name &&
        value.street &&
        value.city &&
        value.state &&
        value.country
      ),
    [value]
  );

  const generateAddressLabel = useCallback(
    (adr: Address | BillToShipToAddress) => {
      const fullAddressFields = [
        adr.name,
        adr.companyName,
        adr.attention,
        adr.street,
        adr.street2,
        adr.city,
        adr.postalCode,
        adr.state,
        adr.country,
      ];

      // show only entered fields
      return fullAddressFields.filter((f) => !!f).join(', ');
    },
    []
  );

  const resetSelectedAddress = useCallback(() => {
    onChange(initialBillToShipToAddress);
  }, [onChange]);

  const changeToSelectedAddress = useCallback(
    (address: Address) => () => {
      onChange(transformAddressToBillToShipToAddress(address));
    },
    [onChange]
  );

  const handleModalCancelClicked = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleSaveModalClicked = useCallback(
    (newAddress: BillToShipToAddress) => {
      onChange(newAddress);
      setModalVisible(false);
    },
    [onChange]
  );

  const menuActionOptions: MenuItem[] = useMemo(
    () => [
      ...additionalActionItems,
      {
        label: fullAddressExists ? 'Edit' : 'Custom',
        icon: IconNames.Mode,
        separateAfter: !!addressChoices.length,
        onOptionClick: () => setModalVisible(true),
        dataQa: `${dataQa}-edit-custom`,
        permissions: permissions,
        disabled: disabled,
      },
      ...addressChoices.map((choice) => ({
        label: generateAddressLabel(choice),
        icon: IconNames.Place,
        onOptionClick: changeToSelectedAddress(choice),
        permissions: permissions,
        disabled: disabled,
      })),
      {
        label: 'Clear',
        icon: <CustomClearIcon />,
        onOptionClick: resetSelectedAddress,
        permissions: permissions,
        disabled: disabled,
      },
    ],
    [
      addressChoices,
      changeToSelectedAddress,
      fullAddressExists,
      generateAddressLabel,
      resetSelectedAddress,
      additionalActionItems,
      dataQa,
      permissions,
      disabled,
    ]
  );

  return (
    <>
      <TextFieldWithActions
        type="text"
        label={label}
        placeholder={placeholder || 'Select address'}
        value={generateAddressLabel(value)}
        fullWidth
        actionItems={menuActionOptions}
        dataQa={dataQa}
        disabled={disabled}
      />
      <AddressModal
        address={value}
        editMode={fullAddressExists}
        modalVisible={modalVisible}
        onCancel={handleModalCancelClicked}
        onSave={handleSaveModalClicked}
        dataQa={dataQa}
        customerId={customerId}
        vendorId={vendorId}
      />
    </>
  );
};

export default memo(TextFieldAddress);
