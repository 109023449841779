import { Item } from 'services/items';
import { Location } from 'services/locations';
import { TrackingType } from 'services/settings/tracking';

export interface ItemInventory {
  availableQty: number;
  committedQty: number;
  allocatedQty: number;
  totalQty: number;
  shortQty?: number;
  totalCost: number;
  availableToPickQty: number;
  incomingQty: number;
  inventoryRowList: InventoryRow[];
  inventoryTableRows: InventoryRow[];
}
export interface InventoryTableRow {
  location: Location;
  subRows: InventoryRow[];
}

export interface InventoryRow {
  id: number;
  availableQty: number;
  committedQty: number;
  totalQty: number;
  shortQty?: number;
  incomingQty: number;
  cost: number | null;
  locationId: number;
  location?: Location;
  availableToPickQty: number;
  trackingGroupList: TrackingGroup[];
  allocatedOnPurchaseOrders: number;
  allocatedOnSalesOrders: number;
  qtyIncomingOnPurchaseOrders: number;
  qtyIncomingOnSalesOrders: number;
}

export interface ItemQuantityRow {
  orderNumber: string;
  quantity: number;
  allocatedQuantity: number;
  committedQuantity: number;
  orderStatus: boolean;
  orderLocation: string;
  orderType: string;
  id: number;
}

export interface TrackingGroup {
  id?: string;
  committedQuantity: number;
  onHand: number;
  quantity: number | null;
  trackingInfoList: TrackingInfo[];
  serialList: SerialRow[];
  serialIds: number[];
  serialQuantity?: number;
}

export interface TrackingInfo {
  id: number;
  trackingTypeId: number;
  value: string | null;
}

export interface SerialRow {
  id: number;
  committed: boolean;
  serialNumbers: SerialColumn;
}

export interface SerialColumn {
  [trakckingId: number]: string | null;
}

export interface MaterialExpensing {
  customerId?: number | null;
  accountingClassId?: number | null;
}

export interface InventoryEventData {
  itemId: number;
  quantity: number;
  locationId: number;
  notes: string | null;
  uomId: number;
  cost: number | null;
  moveToLocationId: number | null;
  trackingGroupObjects: PostTrackingGroup[];
  materialExpensing?: MaterialExpensing;
}

export interface Costing {
  id: number | null;
  dateLastModified: Date | null;
  dateCreated: Date | null;
  item: Item | null;
  itemId: number | null;
  unitCost: number | null;
  totalCost: number | null;
  quantity: number | null;
  originalQuantity: number | null;
  voided: boolean;
}

export interface EditInventoryData {
  itemId: number;
  editNonSerialTrackingInputs?: EditInventoryNonSerialData[];
  editSerialInputs?: EditInventorySerialData[];
}

export interface EditInventoryNonSerialData {
  itemId: number;
  locationId: number | null;
  existingTrackingValues: TrackingInfo[];
  newTrackingValues: TrackingInfo[];
}

export interface EditInventorySerialData extends PostSerialRow {
  serialId: number;
}

export interface PostTrackingGroup {
  quantity: number | null;
  trackingGroupInfos: TrackingInfo[];
  serialInputs: PostSerialRow[];
  serialIds?: number[];
}

export interface PostSerialRow {
  serialNumberInputs: PostSerialColumn[];
}

export interface PostSerialColumn {
  trackingTypeId: number;
  serialNumber: string;
}

export interface BulkWizardItem {
  id: number | null;
  name: string | null;
  description: string | null;
  errorMessage: string | null;
  defaultImage: string | null;
  itemTrackingTypeList: ItemTrackingType[];
  allocatedOnPurchaseOrders: number;
  allocatedOnSalesOrders: number;
  availableQty: number;
  availableToPickQty: number;
  committedQty: number;
  itemId: number | null;
  locationId: number | null;
  notAvailableQty: number;
  qtyIncomingOnPurchaseOrders: number;
  qtyIncomingOnSalesOrders: number;
  qtyShort: number;
  totalQty: number;
  uomId: number | null;
  quantity: number | null;
  moveToLocationId: number | null;
  trackingGroupList: TrackingGroup[];
}

export interface BulkWizardError {
  id: number | null;
  locationId: number | null;
  moveToLocationId: number | null;
  error: string | null;
}

export enum ItemInventoryEvents {
  Add = 'Add',
  Remove = 'Remove',
  Move = 'Move',
  Cycle = 'Cycle',
}

export enum QuantityType {
  Allocated = 'allocated',
  Incoming = 'incoming',
  Committed = 'committed',
}

// TRACKING TYPES
export interface ItemTrackingType {
  id: number;
  nextValue: string | null;
  trackingType: TrackingType | null;
  trackingTypeId: number;
  deleted: boolean;
}
export interface InventoryLocationSummary {
  availableQty: number;
  committedQty: number;
  totalQty: number;
  itemId: number;
  rootLocationId: number;
  qtyIncomingOnPurchaseOrders: number;
  qtyIncomingOnSalesOrders: number;
  allocatedOnPurchaseOrders: number;
  allocatedOnSalesOrders: number;
}

export interface NewItemTrackingType
  extends Omit<ItemTrackingType, 'id' | 'trackingType' | 'deleted'> {}

export interface ItemExistingInventory {
  itemTrackingTypes: ItemTrackingType[];
  trackings: ItemExistingInventoryLocation[];
}

export interface ItemExistingInventoryLocation {
  locationId: number;
  tracking: ItemExistingInventoryTracking[];
}

export interface ItemExistingInventoryTracking {
  trackingTypeId: number;
  value: string | null;
  serialNumbers: string[];
}

export enum SerialListStatus {
  Valid = 'Valid',
  Invalid = 'Invalid',
}

export interface SerialStatusLabel {
  status: SerialListStatus;
  label: string;
}
export interface BulkItem {
  locationFromId: number | null;
  locationToId: number | null;
  notes: string | null;
  quantity: number | null;
  itemId: number | null;
  locationId: number | null;
  uomId: number | null;
  moveToLocationId: number | null;
  trackingGroupObjects: TrackingGroup[] | [];
}

export interface InventoryItem {
  id: number;
  name: string;
  sku: string;
  description: string;
  defaultUomId: number;
  defaultUomAbbreviation: string;
  image: string;
  availableLocationQuantity: number;
}
