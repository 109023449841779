import React, { memo, useCallback, useMemo, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import AutoComplete from '@mui/material/Autocomplete';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from 'react-redux';

import { activeUserHasPermission } from 'services/user/redux';
import { fetchLocation, Location } from 'services/locations';
import { Pagination } from 'services/search';
import { PaginationDirection } from 'services/url';
import { initialPagination } from 'ui/components/Table/ItemsTable';
import { TextField } from 'ui/components/TextField/TextField';

import { useAsyncAutocomplete, useScrollPosition } from '../Autocomplete/hooks';
import { LocationsAutocompleteProps } from './types';
import NewLocationModal from './NewLocationModal';
import {
  locationsAutocompleteFilterOptions,
  locationsAutocompleteOptionLabel,
  resolvedFetchFunction,
} from './helpers';

const initialLocationPagination: Pagination = {
  ...initialPagination,
  pageSize: 50,
  sort: { sortBy: 'name', direction: PaginationDirection.Ascending },
};

const LocationsAsyncAutocomplete: React.FC<LocationsAutocompleteProps> = (
  props
) => {
  const {
    onChange,
    dataQa,
    label,
    value,
    placeholder,
    disabled,
    permissions = [],
    error,
    required,
    parentId,
    companyWide,
    activeId,
    disableAdd = false,
    additionalInputProps,
    expands,
    fullWidth = false,
    customQuickSearchColumns,
    getLocationLabel,
    filterOutLocations,
    renderOption,
    locationTypes,
  } = props;

  const {
    data: locations,
    isLoading,
    innerValue,
    open,
    onCloseAutocomplete,
    onNextPage,
    onOpenAutocomplete,
    setSearchValue,
    setInnerValue,
    setData,
  } = useAsyncAutocomplete(
    value,
    resolvedFetchFunction(
      parentId,
      expands,
      customQuickSearchColumns,
      locationTypes
    ),
    fetchLocation,
    initialLocationPagination
  );

  const [showModal, setShowModal] = useState(false);
  const [newLocationName, setNewLocationName] = useState<string | null>(null);

  const canEdit = useSelector(activeUserHasPermission(permissions));
  const { listRef, captureScrollPosition } = useScrollPosition(locations);

  const isDisabled = disabled || !canEdit;

  const locationOptions = useMemo(() => {
    let tempLocations: Location[] = locations;
    if (filterOutLocations) {
      tempLocations = tempLocations.filter(
        (l) => !filterOutLocations.includes(l.id)
      );
    }

    if (companyWide !== undefined || activeId !== undefined) {
      return tempLocations.filter(
        (l) =>
          (companyWide !== undefined ? l.companyWide === companyWide : true) &&
          (activeId !== undefined ? l.id !== activeId : true)
      );
    }

    return tempLocations;
  }, [companyWide, activeId, locations, filterOutLocations]);

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      if (!val) {
        onOpenAutocomplete();
        setInnerValue(null);
      }
      setSearchValue(val || null);
      onOpenAutocomplete();
      return;
    }

    if (reason === 'clear') {
      onChange(null);
    }

    setSearchValue(null);
  };
  const handleChange = (e: any, location: Location | null) => {
    if (location && (location.id === null || location.id < 0)) {
      setNewLocationName(location.name);
      setShowModal(true);
      return;
    }
    setInnerValue(location);
    onChange(location);
  };

  const handleSaveModal = useCallback(
    async (Location: Location) => {
      setShowModal(false);
      setData([Location]);
      setInnerValue(Location);
      onChange(Location);
    },
    [onChange, setInnerValue, setData]
  );

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <AutoComplete
        className={'redesign'}
        options={locationOptions}
        open={open}
        value={innerValue}
        disabled={isDisabled}
        fullWidth={fullWidth}
        renderOption={
          renderOption
            ? renderOption
            : (props, option: Location) => (
                <li {...props} key={option?.id}>
                  <Typography variant="body1">
                    {!option.id || option.id < 0
                      ? `+ Add "${option.name}"`
                      : option.name}
                  </Typography>
                </li>
              )
        }
        onOpen={onOpenAutocomplete}
        onClose={onCloseAutocomplete}
        onChange={handleChange}
        isOptionEqualToValue={(option, val) => option?.id === val?.id}
        getOptionLabel={locationsAutocompleteOptionLabel(getLocationLabel)}
        filterOptions={locationsAutocompleteFilterOptions(disableAdd)}
        onInputChange={handleAutocompleteInputChange}
        noOptionsText={isLoading ? 'Loading...' : 'No options'}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const node = event.currentTarget;
            if (
              Math.ceil(node.scrollTop + node.clientHeight) ===
              node.scrollHeight
            ) {
              onNextPage();
            }
          },
          // @ts-ignore
          ref: listRef,
          onScrollCapture: captureScrollPosition,
        }}
        renderInput={(params) => {
          // params.InputProps.endAdornment is component with 2 children
          // first child is clear button, so we want to set it to null
          const endAdornment = params.InputProps.endAdornment as any;

          return (
            <TextField
              // we are omitting props that would overwrite our styling in TextField
              {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
              label={label}
              placeholder={placeholder}
              error={error}
              required={required}
              InputProps={{
                ...params.InputProps,
                ...additionalInputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={10} />
                    ) : null}
                    {endAdornment}
                  </React.Fragment>
                ),
              }}
              inputProps={{
                'data-qa': dataQa,
                'data-lpignore': 'true',
                ...params.inputProps,
              }}
            />
          );
        }}
        clearIcon={
          dataQa && (
            <CloseIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-clear-icon`}
            />
          )
        }
        popupIcon={
          dataQa && (
            <ArrowDropDownIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-dropdown-icon`}
            />
          )
        }
      />
      <NewLocationModal
        show={showModal}
        newLocationName={newLocationName}
        onClose={handleCloseModal}
        onSave={handleSaveModal}
      />
    </>
  );
};

export default memo(LocationsAsyncAutocomplete);
