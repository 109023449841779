import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import AutoComplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { activeUserHasPermission } from 'services/userV2/redux';
import { Pagination } from 'services/search';
import { initialPagination } from 'ui/components/Table/ItemsTable';
import { PaginationDirection } from 'services/url';
import { User, fetchUserById } from 'services/userV2';

import { UserAsyncAutocompleteProps } from './types';
import { resolvedUserFunction } from './helpers';

import { useAsyncAutocomplete, useScrollPosition } from '../Autocomplete/hooks';

const initialUserPagination: Pagination = {
  ...initialPagination,
  pageSize: 50,
  sort: { sortBy: 'dateCreated', direction: PaginationDirection.Ascending },
};

const UserAsyncAutocompleteV2: React.FC<UserAsyncAutocompleteProps> = (
  props
) => {
  const {
    onChange,
    dataQa,
    permissions = [],
    disabled,
    label,
    value,
    placeholder,
    error,
    required,
    additionalInputProps,
    customQuickSearchColumns,
    expands,
    helperText,
  } = props;

  const {
    data: user,
    isLoading,
    innerValue,
    open,
    onCloseAutocomplete,
    onNextPage,
    onOpenAutocomplete,
    setSearchValue,
    setInnerValue,
  } = useAsyncAutocomplete(
    value,
    resolvedUserFunction(expands, customQuickSearchColumns),
    fetchUserById,
    initialUserPagination
  );

  const canEdit = useSelector(activeUserHasPermission(permissions));
  const { listRef, captureScrollPosition } = useScrollPosition(user);

  const isDisabled = disabled || !canEdit;

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      if (!val) {
        onOpenAutocomplete();
        setInnerValue(null);
      }
      setSearchValue(val || null);
      onOpenAutocomplete();
      return;
    }

    if (reason === 'clear') {
      onChange(null);
    }

    setSearchValue(null);
  };

  const handleChange = (e: any, user: User | null) => {
    setInnerValue(user);
    onChange(user);
  };

  return (
    <AutoComplete
      className="redesign"
      style={{ marginTop: '0px', marginBottom: '0px' }}
      options={user}
      open={open}
      disabled={isDisabled}
      value={innerValue}
      onOpen={onOpenAutocomplete}
      onClose={onCloseAutocomplete}
      onChange={handleChange}
      onInputChange={handleAutocompleteInputChange}
      getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
      filterOptions={(options, params) =>
        createFilterOptions<User>()(options, params)
      }
      noOptionsText={isLoading ? 'Loading...' : 'No options'}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const node = event.currentTarget;
          if (
            Math.ceil(node.scrollTop + node.clientHeight) === node.scrollHeight
          ) {
            onNextPage();
          }
        },
        // @ts-ignore
        ref: listRef,
        onScrollCapture: captureScrollPosition,
      }}
      renderInput={(params) => {
        const endAdornment = params.InputProps.endAdornment;

        return (
          <TextField
            // we are omitting props that would overwrite our styling in TextField
            {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
            className="redesign"
            label={label}
            placeholder={placeholder}
            error={error}
            required={required}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              ...additionalInputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {endAdornment}
                </React.Fragment>
              ),
            }}
            inputProps={{
              'data-qa': dataQa,
              'data-lpignore': 'true',
              ...params.inputProps,
            }}
          />
        );
      }}
      clearIcon={
        dataQa && (
          <CloseIcon
            fontSize="small"
            data-qa={`${dataQa}-autocomplete-clear-icon`}
          />
        )
      }
      popupIcon={
        dataQa && (
          <ArrowDropDownIcon
            fontSize="small"
            data-qa={`${dataQa}-autocomplete-dropdown-icon`}
          />
        )
      }
    />
  );
};

export default memo(UserAsyncAutocompleteV2);
