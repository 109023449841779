import React, { useState, useCallback } from 'react';
import { Box } from '@mui/material';

import { setAdminAccessToken } from 'services/auth/services';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import {
  isUserExpired,
  logInAsUser,
  connectToUserOrganization,
} from 'services/user';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';

import { RemoteSupportLogInModalProps } from './types';
import { resolveModalBody } from './helpers';
import { showNotification } from 'services/api';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'helpers/useFlags';

const RemoteSupportLogInModal: React.FC<RemoteSupportLogInModalProps> = ({
  userToLoginAs,
  onClose,
  showModal,
  setRedirect,
}) => {
  const [authorizationKey, setAuthorizationKey] = useState('');
  const [showExpirationModal, setShowExpirationModal] = useState(false);
  const [error, setError] = useState(false);
  const flags = useFlags();
  const { loginWithRedirect, user } = useAuth0();

  const handleTextFieldChange = useCallback(
    (e: any) => setAuthorizationKey(e.target.value),
    []
  );

  const handleLoginAsUserLegacy = useCallback(async () => {
    if (!userToLoginAs.email) {
      return;
    }

    let token;

    try {
      token = await logInAsUser(userToLoginAs.email, authorizationKey);
      setAdminAccessToken(token);
    } catch {
      // ignore error
    }

    // if token is provided, close modal and redirect
    if (!!token) {
      onClose();
      setRedirect(true);
      window.location.reload();
    }
  }, [userToLoginAs, authorizationKey, onClose, setRedirect]);

  const handleLoginAsUserSSO = useCallback(async () => {
    if (!userToLoginAs.email) {
      return;
    }

    try {
      // user is not logged in as an admin
      const { organizationId } = await connectToUserOrganization(
        user?.email,
        userToLoginAs.email,
        authorizationKey
      );
      await loginWithRedirect({
        authorizationParams: {
          organization: organizationId,
        },
      });
    } catch {
      // ignore error
    }
  }, [userToLoginAs, authorizationKey, onClose, setRedirect]);

  const handleLoginAsUser = useCallback(async () => {
    if (flags?.driveAuth0Enabled) {
      await handleLoginAsUserSSO();
      return;
    }
    await handleLoginAsUserLegacy();
  }, [handleLoginAsUserLegacy, handleLoginAsUserSSO, flags?.driveAuth0Enabled]);

  const handleCancelClick = () => {
    onClose();
    setAuthorizationKey('');
    setError(false);
  };

  const onLogInModalApplyClicked = useCallback(() => {
    const expired = isUserExpired(userToLoginAs);

    if (!authorizationKey) {
      setError(true);
      showNotification('Please enter admin key', { variant: 'warning' });
      return;
    }
    if (expired) {
      setShowExpirationModal(expired);
      return;
    }

    handleLoginAsUser().then();
  }, [userToLoginAs, handleLoginAsUser, authorizationKey]);

  return (
    <>
      <Modal
        open={showModal}
        title="Log In As User"
        applyLabel="Log In"
        onCancelClicked={handleCancelClick}
        onApplyClicked={onLogInModalApplyClicked}
      >
        <Box mb={2}>
          <TextField
            type="password"
            label="Admin Key"
            placeholder="Enter admin key"
            fullWidth
            autoComplete="off"
            required
            onChange={handleTextFieldChange}
            value={authorizationKey}
            error={error}
            className="redesign"
            variant="standard"
          />
        </Box>
      </Modal>
      <ConfirmationModal
        open={showExpirationModal}
        title="Account has expired"
        body={resolveModalBody(userToLoginAs)}
        onCancelClicked={() => setShowExpirationModal(false)}
        onConfirmClicked={handleLoginAsUser}
        confirmLabel="OK"
      />
    </>
  );
};

export default RemoteSupportLogInModal;
