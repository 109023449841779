import axios from 'axios';
import {
  commerceApi,
  identityApi,
  imageApi,
  integrationsApi,
  reportingApi,
  shippingIntegrationsApi,
} from 'services/api/config';

const services = [
  axios,
  reportingApi,
  commerceApi,
  imageApi,
  integrationsApi,
  shippingIntegrationsApi,
];

export const setAccessToken = (
  accessToken: string,
  tokenVersion = 1,
  requestInterceptor: any = null,
  responseErrorHandler: any = null
) => {
  for (const service of services) {
    service.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
    if (tokenVersion === 2) {
      service.defaults.headers.common.fishbowl_token_version = 2;
    }
    // add the interceptor only if it is provided
    if (requestInterceptor) {
      service.interceptors.request.use(requestInterceptor);
    }
    if (responseErrorHandler) {
      service.interceptors.response.use(
        (response) => response,
        responseErrorHandler
      );
    }
  }
  if (!tokenVersion || tokenVersion === 1) {
    sessionStorage.setItem('accessToken', accessToken);
    localStorage.setItem('accessToken', accessToken);
  }
  identityApi.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
};

export const clearAuth0AccessToken = () => {
  for (const service of services) {
    service.defaults.headers.common.Authorization = '';
  }
};

export const getAccessToken = (): string | null => {
  return (
    localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
  );
};

export const clearAccessToken = () => {
  axios.defaults.headers.common.Authorization = '';
  reportingApi.defaults.headers.common.Authorization = '';
  commerceApi.defaults.headers.common.Authorization = '';
  imageApi.defaults.headers.common.Authorization = '';
  integrationsApi.defaults.headers.common.Authorization = '';
  sessionStorage.removeItem('accessToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('adminAccessToken');
  localStorage.removeItem('auth0_remoteOrganization');
};

export const setAdminAccessToken = (remoteUserToken: string) => {
  localStorage.setItem('adminAccessToken', getAccessToken()!);
  setAccessToken(remoteUserToken);
};

export const clearAdminAccessToken = () => {
  setAccessToken(localStorage.getItem('adminAccessToken')!);
  localStorage.removeItem('adminAccessToken');
};

export const getAdminAccessToken = (): string | null => {
  return localStorage.getItem('adminAccessToken');
};

export const setRemoteOrganizationData = (data: {
  organizationId: string;
  userEmail: string;
  adminSecret: string; // Needed for disconnecting. TODO: should not be needed. Work with backend team to remove.
}) => {
  return localStorage.setItem('auth0_remoteOrganization', JSON.stringify(data));
};

export const getRemoteOrganizationData = (): {
  organizationId: string;
  userEmail: string;
  adminSecret: string;
} | null => {
  const dataString = localStorage.getItem('auth0_remoteOrganization');
  try {
    return dataString ? JSON.parse(dataString) : null;
  } catch {
    return null; // In case of invalid JSON
  }
};
