import { InventoryItem, TrackingGroup } from 'services/inventory';
import { Item } from 'services/items';

export interface QuickTransferModalProps {
  item: Item;
  show: boolean;
  locationId?: number | null;
  onCancelClicked(): void;
  setShowQuickTransferModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface amountToTransferInputProps {
  formValues: QuickTransferFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<QuickTransferFormValues>>;
  value: number | null;
  hasError: boolean;
  errorMessage?: string;
  label?: boolean;
}
export interface QuickTransferFormProps {
  formValues: QuickTransferFormValues;
  validationErrors?: any;
  setFormValues: React.Dispatch<React.SetStateAction<QuickTransferFormValues>>;
  hasError?: boolean;
  errorMessage?: string;
  setItems: React.Dispatch<React.SetStateAction<Item[]>>;
  meta?: any;
  setQtyErrorMessage?: any;
}
export interface QuickTransferFormValues {
  locationFromId: number | null;
  locationToId: number | null;
  notes: string | null;
  quantity: number | null;
  inventoryItem: InventoryItem | null;
  Item: Item | null;
  id: number | null;
  errorStatus: boolean | false;
  trackingGroupObjects: TrackingGroup[] | null;
  locationFromName?: string | null;
  locationToName?: string | null;
}

export const defaultQuickTransferFormValues: QuickTransferFormValues = {
  locationFromId: null,
  locationToId: null,
  notes: null,
  quantity: null,
  inventoryItem: null,
  Item: null,
  id: null,
  errorStatus: false,
  trackingGroupObjects: [],
};
export interface quickTransferSubRowProps {
  locationFromId: number | null;
  locationToId: number | null;
  notes: string | null;
  quantity: number | null;
  Item: Item | null;
  id: number | null;
}
