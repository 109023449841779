import React, { useEffect } from 'react';

import { Routes } from '../../navigation';
import { RouteComponentProps } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { clearAuth0AccessToken } from 'services/auth/services';

interface LogoutPageCmp extends React.FunctionComponent<LogoutPage> {
  route: string;
}

interface LogoutPage extends RouteComponentProps {}

const LogoutPage: LogoutPageCmp = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    const logoutUser = async () => {
      clearAuth0AccessToken();
      await logout({
        logoutParams: { returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL },
      });
    };
    logoutUser();
  }, []);

  return <></>;
};

LogoutPage.route = Routes.LogoutPage;

export default LogoutPage;
