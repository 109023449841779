import { fetchInventoryItemsByLocation } from 'services/inventory';
import { Pagination } from 'services/search';

export const resolvedFetchItemsForBulkMoveFunction = (
  locationId: number | null
) => {
  return async (pagination: Pagination, searchValue: string | null) => {
    const data = await fetchInventoryItemsByLocation(
      {
        pagination,
        quickSearchValue: searchValue,
      },
      locationId
    );

    return data;
  };
};
