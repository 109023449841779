import React from 'react';

import { Page } from 'ui/components/Page/Page';
import { InsightsPageCmp, InsightsPageProps } from './types';
import { Routes } from '../../navigation';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Insights: InsightsPageCmp = (props: InsightsPageProps) => {
  return (
    <Page
      margin="-16px"
      width="calc(100% + 32px);"
      height="calc(100% + 32px);"
      border="none"
    >
      <iframe
        src="https://tanstack.com/table/latest"
        title="test page"
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </Page>
  );
};

Insights.route = Routes.InsightsPage;

export default Insights;
