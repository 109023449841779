import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { transformTrackingType } from 'services/settings/tracking';
import { transformLocation } from 'services/locations';
import { resolveImageUrl } from 'helpers';

import {
  InventoryEventData,
  InventoryLocationSummary,
  InventoryRow,
  ItemInventory,
  ItemQuantityRow,
  ItemTrackingType,
  PostSerialRow,
  SerialColumn,
  SerialRow,
  TrackingGroup,
  TrackingInfo,
  BulkWizardItem,
  BulkWizardError,
  ItemInventoryEvents,
  Costing,
  BulkItem,
  InventoryItem,
} from './types';
import { QuickTransferFormValues } from 'ui/modules/materials/pages/InventoryPage/components/QuickTransferModal/types';

export const transformItemInventory = (res: any): ItemInventory => {
  const inventoryDtoList = res.itemLocationInventories || [];
  const inventoryRowList = inventoryDtoList
    .filter(
      (row: any) =>
        row.availableQty > 0 ||
        row.allocatedOnSalesOrders > 0 ||
        row.allocatedOnPurchaseOrders > 0 ||
        row.committedQty > 0 ||
        row.qtyIncomingOnPurchaseOrders > 0 ||
        row.notAvailableQty > 0 ||
        row.qtyShort > 0 ||
        row.qtyIncomingOnSalesOrders
    )
    .map((row: any) => transformInventoryDto(row));

  return {
    availableQty: res.availableQty,
    committedQty: res.committedQty || 0,
    totalQty: res.totalQty || 0,
    totalCost: res.totalCost || 0,
    allocatedQty: res.allocatedQty || 0,
    incomingQty: res.incomingQty || 0,
    availableToPickQty: res.availableToPickQty || 0,
    inventoryRowList,
    inventoryTableRows: inventoryRowList,
    shortQty: res.shortQty,
  };
};

export const transformBulkWizardItem = (res: any): BulkWizardItem => {
  const responseDefaultImage = (res.images || []).find(
    (i: any) => i.defaultFlag
  );

  const item = {
    id: null,
    errorMessage: null,
    name: res.itemName,
    description: res.itemDescription,
    defaultImage: responseDefaultImage
      ? resolveImageUrl(responseDefaultImage.url)
      : null,
    itemTrackingTypeList: [],
  };

  return res.locationSummaries
    .filter((ls: any) => ls.availableQty > 0)
    .map((ls: any) => ({
      ...item,
      ...transformBulkWizardItemLocationSummary(ls),
    }));
};

export const transformToRequestBulkWizardItem = (i: BulkItem) => {
  return {
    itemId: i.itemId,
    inventoryEventType: ItemInventoryEvents.Move,
    quantity: i.quantity,
    locationId: i.locationId,
    fromLocationId: i.locationId,
    uomId: i.uomId,
    moveToLocationId: i.moveToLocationId,
    notes: i.notes,
    trackingGroupObjects: i.trackingGroupObjects,
  };
};

export const transformBulkWizardError = (res: any): BulkWizardError => {
  return {
    error: res.error,
    id: res.inventoryEventInput.itemId,
    locationId: res.inventoryEventInput.locationId,
    moveToLocationId: res.inventoryEventInput.moveToLocationId,
  };
};

export const transformBulkWizardItemLocationSummary = (res: any) => {
  return {
    availableQty: res.availableQty,
    committedQty: res.committedQty,
    totalQty: res.totalQty,
    itemId: res.itemId,
    locationId: res.locationId,
    qtyIncomingOnPurchaseOrders: res.qtyIncomingOnPurchaseOrders,
    qtyIncomingOnSalesOrders: res.qtyIncomingOnSalesOrders,
    allocatedOnPurchaseOrders: res.allocatedOnPurchaseOrders,
    allocatedOnSalesOrders: res.allocatedOnSalesOrders,
    availableToPickQty: res.availableToPickQty,
    notAvailableQty: res.notAvailableQty,
    qtyShort: res.qtyShort,
    uomId: res.uomId,
    quantity: null,
    moveToLocationId: null,
    trackingGroupList: [],
  };
};

export const transformInventoryDto = (res: any): InventoryRow => {
  return {
    id: res.locationId, // We need id in ItemsTable component and locactionId is unique
    allocatedOnPurchaseOrders: res.allocatedOnPurchaseOrders,
    allocatedOnSalesOrders: res.allocatedOnSalesOrders,
    qtyIncomingOnPurchaseOrders: res.qtyIncomingOnPurchaseOrders,
    qtyIncomingOnSalesOrders: res.qtyIncomingOnSalesOrders,
    availableToPickQty: res.availableToPickQty || 0,
    availableQty: res.availableQty,
    committedQty: res.committedQty,
    totalQty: res.totalQty,
    shortQty: res.qtyShort,
    incomingQty: res.incomingQty,
    cost: res.cost,
    locationId: res.locationId,
    location: transformLocation(res.location),
    trackingGroupList: res.trackingGroupObjects.map((t: any) =>
      transformTrackingGroupDto(t)
    ),
  };
};

export const transformItemQuantity = (res: any): ItemQuantityRow => {
  return {
    orderNumber: res.orderNumber,
    quantity: res.quantity,
    orderType: res.orderType,
    orderStatus: res.orderStatus,
    allocatedQuantity: res.allocatedQuantity,
    committedQuantity: res.committedQuantity,
    orderLocation: res.location.name,
    id: res.orderId,
  };
};

export const transformSerialDtoListToSerialRow = (res: any): SerialRow => {
  const serialNumbers = res.serialNumberInputs.reduce(
    (acc: SerialColumn, item: any) => {
      acc[item.trackingTypeId] = item.serialNumber;
      return acc;
    },
    {}
  );

  return {
    id: res.serialId,
    committed: res.committed,
    serialNumbers,
  };
};

export const transformTrackingGroupDto = (res: any): TrackingGroup => ({
  id: uuidv4(),
  committedQuantity: res.committedQty || 0,
  onHand: res.quantity,
  quantity: 0,
  trackingInfoList: (res.trackingGroupInfos || []).map((t: any) =>
    transformTrackingInfo(t)
  ),
  serialList: (res.serialInputs || []).map((s: any) =>
    transformSerialDtoListToSerialRow(s)
  ),
  serialIds: [],
});

export const transformTrackingInfo = (res: any): TrackingInfo => ({
  id: res.id,
  trackingTypeId: res.trackingTypeId,
  value: res.value,
});

export const transformItemTrackingType = (res: any): ItemTrackingType => {
  return {
    id: res.id,
    nextValue: res.nextValue,
    trackingType: res.trackingType
      ? transformTrackingType(res.trackingType)
      : null,
    trackingTypeId: res.trackingTypeId,
    deleted: false,
  };
};

export const transformSerialRowToPostSerialRow = (
  serialRow: SerialRow
): PostSerialRow => {
  return {
    serialNumberInputs: Object.keys(serialRow.serialNumbers).map(
      (trackingTypeId) => {
        const trackingTypeIdNum = parseInt(trackingTypeId, 10);
        return {
          trackingTypeId: trackingTypeIdNum,
          serialNumber: serialRow.serialNumbers[trackingTypeIdNum]!,
        };
      }
    ),
  };
};

export const transformInventoryLocationSummaryList = (
  res: any
): InventoryLocationSummary => {
  return {
    availableQty: res.availableQty,
    committedQty: res.committedQty,
    totalQty: res.totalQty,
    itemId: res.itemId,
    rootLocationId: res.rootLocationId,
    qtyIncomingOnPurchaseOrders: res.qtyIncomingOnPurchaseOrders,
    qtyIncomingOnSalesOrders: res.qtyIncomingOnSalesOrders,
    allocatedOnPurchaseOrders: res.allocatedOnPurchaseOrders,
    allocatedOnSalesOrders: res.allocatedOnSalesOrders,
  };
};

export const transformToPostInventoryEvent = (
  data: InventoryEventData,
  itemHasTracking: boolean
): any => {
  if (!itemHasTracking) {
    return _.omit(data, ['trackingGroupObjects']);
  }

  return data;
};

export const transformCosting = (res: any): Costing => {
  return {
    id: res.id,
    dateLastModified: res.dateLastModified,
    dateCreated: res.dateCreated,
    item: res.item,
    itemId: res.itemId,
    unitCost: res.unitCost,
    totalCost: res.totalCost,
    quantity: res.quantity,
    originalQuantity: res.originalQuantity,
    voided: res.voided,
  };
};
export const createBulkItemsForQuickTransfer = (
  data: QuickTransferFormValues[]
) => {
  const bulkItems = data.map((item) => ({
    locationFromId: item.locationFromId,
    locationToId: item.locationToId,
    notes: item.notes,
    quantity: item.quantity,
    itemId: item.Item?.id ?? null,
    locationId: item.locationFromId ?? null,
    uomId: item.Item?.defaultUomId ?? null,
    moveToLocationId: item.locationToId ?? null,
    trackingGroupObjects: item.trackingGroupObjects ?? [],
  }));
  return bulkItems;
};

export const transformInventoryItem = (res: any): InventoryItem => {
  return {
    id: res.id,
    name: res.name,
    sku: res.sku,
    description: res.description,
    defaultUomId: res.defaultUomId,
    defaultUomAbbreviation: res.defaultUomAbbr,
    image: res.image,
    availableLocationQuantity: res.totalLocationQty,
  };
};
