import React from 'react';
import { ReconnectBannerProps } from './types';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useReconnectBannerStyle } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { useFlags } from 'helpers/useFlags';

export const ReconnectBanner: React.FC<ReconnectBannerProps> = ({
  sellwareUrl,
  handleReconnectClick,
}) => {
  const classes = useReconnectBannerStyle();

  const isDriveAuth0Enabled = useFlags().driveAuth0Enabled;

  return (
    <Box
      pl={1}
      sx={{
        margin: 'calc(-18rem/16) calc(-18rem/16) calc(18rem/16)',
      }}
      className={classes.wrapper}
      data-qa="sellware-reconnect-banner"
    >
      <ErrorOutlineIcon
        sx={{
          width: '20px',
          height: '20px',
          color: '#A50808',
        }}
      />
      <Typography className={classes.captionText}>
        Fishbowl Commerce Disconnected
      </Typography>
      <Typography variant="body1" flexGrow={1}>
        Reconnect with Fishbowl Commerce to continue utilizing commerce channels
      </Typography>
      {isDriveAuth0Enabled ? (
        <FBOButton
          variant="primary"
          color="negative"
          size="medium"
          data-qa="reconnect-to-sellware-button"
          onClick={handleReconnectClick}
        >
          Reconnect
        </FBOButton>
      ) : (
        <FBOButton
          variant="primary"
          color="negative"
          size="medium"
          data-qa="reconnect-to-sellware-button"
          href={sellwareUrl}
          linkStyledAsButton
        >
          Reconnect
        </FBOButton>
      )}
    </Box>
  );
};
