import { defaultMaximumPagination, RequestConfig } from 'helpers';
import { AccountType } from 'services/accounting';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';
import { transformQuickbooks } from './transformations';
import { Quickbooks } from './types';

export const fetchQuickbooksAuthUrl = async (): Promise<string> => {
  const response = await createApiCall(
    {
      path: '/integrations/qbo/auth',
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return response.data;
};

export const exportToQuickbooks = async (): Promise<string> => {
  const response = await createApiCall(
    {
      path: '/integrations/qbo/export',
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Export to QuickBooks successful.',
      },
    }
  )();

  return response.data;
};

export const fetchTaxExemptionCode = async (): Promise<
  {
    id: number;
    reason: string;
  }[]
> => {
  const response = await createApiCall(
    {
      path: '/integrations/qbo/taxExemptionCode',
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return response.data;
};

export const fetchQuickBooksAccountsAPI = async (
  config: RequestConfig,
  accountType: AccountType | null,
  listId: string[] | null
): Promise<DataWithPagination<Quickbooks>> => {
  const {
    pagination = defaultMaximumPagination,
    customQuickSearchColumns = ['name'],
    quickSearchValue,
  } = config;
  let path = `/integrations/qbo/accounts`;

  if (accountType) {
    path += `?accountType=${accountType}`;
  }

  if (listId && listId.length > 0) {
    path += (accountType ? `&` : `?`) + `listId=${listId}`;
  }

  if (quickSearchValue) {
    path +=
      (accountType || listId ? `&` : `?`) +
      `quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  const res = await paginatedApiCall(path, pagination, transformQuickbooks);
  return res;
};
